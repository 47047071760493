.comments-carousel {
    text-align: center;
    padding-top: 60px;
    .carousel-item {
        .text {
            font-family: Merriweather, sans-serif;
            color: #333333;
            font-size: 20px;
            font-style: italic;
            line-height: 36px;
            margin-bottom: 30px;
        }
        .avatar {
            margin-bottom: 15px;
            text-align: center;
            img {
                width: 60px;
                height: 60px;
                vertical-align: middle;
                border-radius: 50%;
                margin: 0 auto;
            }
        }
        .author {
            font-family: Montserrat, sans-serif;
            color: #666666;
            font-size: 12px;
            font-weight: 400;
            .name {
                color: #333333;
                font-size: 12px;
                font-weight: 700;
            }
        }
    }
    .owl-nav {  
        position: absolute;
        left: 0;
        right: 0;
        bottom: 38px;
        .owl-prev {
            padding: 10px 28px 8px 0;
            border-top: 2px solid #cccccc;
            border-right: 2px solid #cccccc;
            position: relative;
            display: inline-block;
            margin-right: 100px;
            &:after {
                content: "";
                display: block;
                width: 50%;
                height: 2px;
                background: #cccccc;
                position: absolute;
                bottom: 0;
                right: 0;
            }
            &:before {
                content: "\f177";
                color: #ccc;
                line-height: 8px;
                font-size: 10px;
                font-family: FontAwesome;
            }
            &:hover {
                border-color: $green;
                &:after {
                    background: $green;
                }
                &:before {
                    color: $green;
                }
            }
        }
        .owl-next {
            padding: 10px 0 8px 28px;
            border-top: 2px solid #cccccc;
            border-left: 2px solid #cccccc;
            position: relative;
            display: inline-block;
            margin-left: 100px;
            &:after {
                content: "";
                display: block;
                width: 50%;
                height: 2px;
                background: #cccccc;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            &:before {
                content: "\f178";
                color: #ccc;
                line-height: 8px;
                font-size: 10px;
                font-family: FontAwesome;
            }
            &:hover {
                border-color: $green;
                &:after {
                    background: $green;
                }
                &:before {
                    color: $green;
                }
            }
        }
    }
}