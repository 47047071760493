.load-more-button {
    .btn {
        border: 2px solid #666666;
        vertical-align: middle;
        &:after {
            border-color: transparent transparent $green $green;
        }
    }
    span {
        width: 20px;
        height: 2px;
        background: #666666;
        display: inline-block;
        vertical-align: middle;
        margin: 0 15px;
    }
}