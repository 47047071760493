.alert {
    padding: 5px 20px;
    line-height: 30px;
    border-radius: 0;
    border: none;
    color: #fff;
    &.alert-default {
        background: #eeeeee;
        color: #333333;
        span {
            color: #333;
        }
    }
    &.alert-warning {
        background: #d3ce5e;
    }
    &.alert-danger {
        background: #bc5058;
    }
    &.alert-success {
        background: #5cae4a;
    }
    &.alert-info {
        background: #497aab;
    }
    &.alert-default-2 {
        background: #7e49ab;
    }
    span {
        margin-right: 20px;
        font-size: 16px;
        color: #fff;
    }
    &.alert-dismissable {
        position: relative;
        padding: 30px 60px 30px 120px;
        background: #f5f5f5;
        border: 1px solid #eeeeee;
        color: #333333;
        .alert-icon {
            position: absolute;
            top: 50%;
            height: 50px;
            left: 50px;
            margin-top: -25px;
            line-height: 50px;
            font-size: 30px;
            min-width: 30px;
            text-align: center;
        }
        .close {
            position: absolute;
            top: 50%;
            right: 15px;
            color: #666666;
            margin-top: -10px;
            opacity: 1;
        }
        .title {
            font-family: Lato, sans-serif;
            color: #333333;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
        }
        &.alert-warning {
            background: #eff0d4;
            border: none;
            .alert-icon, .close .fa {
                color: #d3ce5e;
            }
        }
        &.alert-danger {
            background: #f1d6d4;
            border: none;
            .alert-icon, .close .fa {
                color: #bc5058;
            }
        }
        &.alert-success {
            background: #cfebd5;
            border: none;
            .alert-icon, .close .fa {
                color: #5cae4a;
            }
        }
    }
}
