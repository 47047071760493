.sidebar {
    border-left: 1px solid #eeeeee;
    //padding-left: 30px;
    padding-left: 15px;
}

@media (max-width: 991px) {
    .sidebar {
        border-left: none;
    }
}