.section-special-info {
  &.blue-section {
    background: $blue;
    color: #fff;

    .special-info-item {
      border-color: #7e90af;
      &:hover, &.active {
        background: #fff;
        border-color: #fff;
        &:before, &:after {
          display: none;
        }
        .item-icon {
          background: $green;
        }
        .item-content {
          &:before {
            background: #cccccc;
          }
          .item-name {
            color: #333;
          }
          .item-description {
            color: #666666;
          }
        }
      }
      .item-icon {
        border-radius: 0;
      }
      .item-content {
        &:before {
          background: #cccccc;
        }
        .item-name {
          color: #fff;
        }
        .item-description {
          color: #c4cee4;
        }
      }
      &:before {
        background: #7e90af;
      }
      &:after {
        background: #7e90af;
      }
    }

  }
}

.special-info-item {
  padding: 25px 55px;
  border: 1px solid #dddddd;
  position: relative;
  margin-bottom: 25px;
  transition: all 0.3s ease-out;
  &:hover, &.active {
    background: #f5f5f5;
    border-color: #f5f5f5;
    &:before, &:after {
      display: none;
    }
    .item-icon {
      background: $blue;
    }
  }
  .item-icon {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    background: $green;
    border-radius: 50%;
    line-height: 80px;
    color: #fff;
    text-align: center;
    font-size: 26px;
    transition: all 0.3s ease-out;
  }
  .item-content {
    position: relative;
    &:before {
      content: '';
      top: 50%;
      height: 2px;
      width: 20px;
      position: absolute;
      margin-top: -1px;
      background: #cccccc;
    }
    .item-name {
      font-family: Lato, sans-serif;
      color: #333333;
      font-size: 44px;
      font-weight: 300;
    }
    .item-description {
      font-family: Lato, sans-serif;
      color: #666666;
      font-size: 14px;
      font-style: italic;
      line-height: 28px;
    }
  }
  &.right-space {
    border-right: none;
    &:before, &:after {
      right: 0;
    }
    .item-icon {
      left: -40px;
    }
    .item-content {
      padding-left: 45px;
      &:before {
        left: 0;
      }
    }
  }
  &.left-space {
    border-left: none;
    text-align: right;
    &:before, &:after {
      left: 0;
    }
    .item-icon {
      right: -40px;
    }
    .item-content {
      padding-right: 45px;
      &:before {
        right: 0;
      }
    }
  }
  &:before {
    content: "";
    height: 20px;
    position: absolute;
    top: 0;
    width: 1px;
    background: #dddddd;
  }
  &:after {
    content: "";
    height: 20px;
    position: absolute;
    bottom: 0;
    width: 1px;
    background: #dddddd;
  }
}

@media (max-width: 767px) {
  .special-info-item {
    padding-top: 15px;
    padding-bottom: 15px;
    &.right-space {
      padding-right: 15px;
      .item-content {
        padding-left: 35px;
      }
    }
    &.left-space {
      padding-left: 15px;
    }
  }
}