.welcome-feature {
    display: inline-block;
    vertical-align: top;
    margin: 0 25px 30px;
    text-align: center;
    &.active, &:hover {
        .feature-icon {
            background: $green;
            border-color: $green;
            &:before {
                border-top: 1px solid $green;
                border-bottom: 3px solid $green;
                background: $green;
            }
            &:after {
                border-top: 1px solid $green;
                border-bottom: 3px solid $green;
                background: $green;
            }
        }
    }
    .feature-icon {
        border-left: 1px solid #798db2;
        border-right: 1px solid #798db2;
        border-bottom: 1px solid #798db2;
        position: relative;
        padding: 50px 40px;
        text-align: center;
        color: #fff;
        font-size: 32px;
        margin-bottom: 35px;  
        display: inline-block;
        span {
            display: block;
            width: 40px;
            height: 40px;
        }
        &:before {
            content: "";
            width: 25%;
            position: absolute;
            top: -3px;
            left: -1px;
            height: 3px;
            display: block;
            border-top: 1px solid #798db2;
            border-left: 1px solid #798db2;
        }
        &:after {
            content: "";
            width: 25%;
            position: absolute;
            top: -3px;
            right: -1px;
            height: 3px;
            display: block;
            border-top: 1px solid #798db2;
            border-right: 1px solid #798db2;
        }
    }
    .feature-name {
        font-family: Raleway, sans-serif;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    .feature-text {
        font-family: Lato, sans-serif;
        color: #c4cfe4;
        font-size: 14px;
        font-weight: 300;
    }
}

.section-heading {
    .heading-title {
        font-family: Raleway, sans-serif;
        color: #333333;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 25px;
    }
    .heading-text {
        font-family: Lato, sans-serif;
        color: #666666;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        padding-left: 80px;
        position: relative;
        &:before {
            display: block;
            content: "";
            position: absolute;
            top: 12px;
            left: 0;
            width: 50px;
            background: #cccccc;
            height: 2px;
        }
    }
}

@media (max-width: 1199px) {
    .welcome-feature {
        margin: 0 10px 30px;
    }
}