.newsline-row {
    margin-bottom: 60px; 
}
.news-item {
    position: relative;
    padding-top: 5px;
    &.right-item {
        padding-left: 60px;
        text-align: left;
        margin-left: -35px;
        &:before {
            left: 0; 
        }
    }
    &.left-item {
        padding-right: 60px;
        text-align: right;
        margin-right: -35px;
        &:before {
            right: 0; 
        }
    }
    &:before {
        content: "";
        height: 2px;
        width: 40px;
        background: #cccccc;
        display: block;
        position: absolute;
        top: 15px;
    }
    .author {
        color: #666666;
        font-size: 14px;
        font-weight: 300;
        font-style: italic;
        line-height: 22px;
        margin-bottom: 5px;
    }
    .title {
        font-family: Raleway, sans-serif;
        color: #333333;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        margin-bottom: 25px;
        text-transform: uppercase;
    }
    .comments {
        color: #666666;
        font-size: 14px;
        font-weight: 300;
        display: inline-block;
        font-style: italic;
        line-height: 22px;
        margin-right: 15px;
        span {
            color: #5cae4a;
        }
    }
    .share {
        color: #5cae4a;
        font-size: 14px;
        font-weight: 300;
        font-style: italic;
        line-height: 22px;
    }
}

.news-image {
    &.left-image {
        padding-right: 30px;
    }
    &.right-image {
        padding-left: 30px;
    }
    img {
        max-width: 100%;
    }
}

@media (max-width: 991px) {
    .news-image {
        padding: 0 0 0 0;
        &.left-image {
            padding: 0 0 0 60px;
            margin-bottom: 10px;
        }
        &.right-image {
            padding: 0 60px 0 0;
            margin-top: 10px;
            text-align: right;
        }
    }
    .newsline-row {
        &:nth-child(even) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .news-item {
        padding: 0;
        &.right-item {
            margin: 0;
        }
        &.left-item {
            margin: 0;
        }
    }
}