.btn {
    padding: 0 35px;
    line-height: 41px;
    border-radius: 0;
    border: 2px solid transparent;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    color: #ffffff;
    box-shadow: none !important;
    font-size: 12px; 
    font-weight: 400;
    &:after {
        content: '';
        width: 0;
        height: 0;
        border: 4px solid transparent;
        display: inline-block;
        vertical-align: baseline;
        margin-left: 10px;
        border-left-color: #fff;
        border-bottom-color: #fff;
    }
    &.btn-default {
        background: #fff;
        color: #333;
        border-color: #fff;
        &:after {
            border-color: transparent transparent #333 #333;
        }
        &:focus {
            background: #fff;
        }
        &:hover, &:active {
            background: darken(#fff, 10%);
        }
        &.btn-transparent {
            color: #fff;
            background: none;
            &:after {
                border-color: transparent transparent #fff #fff;
            }
            &:focus {
                color: #fff;
                background: none;
            }
            &:hover, &:active {
                background: #fff;
                border-color: #fff;
                color: #333;
                &:after {
                    border-color: transparent transparent #333 #333;
                }
            }
        }
    }
    &.btn-primary {
        background: $blue;
        border-color: $blue;
        &:focus {
            background: $blue;
            border-color: $blue;
        }
        &:hover, &:active {
            background: darken($blue, 10%);
            border-color: darken($blue, 10%);
        }
    }
    &.btn-gray {
        background: #666666;
        color: #fff;
        border-color: #666666;
        &:after {
            border-color: transparent transparent #5cae4a #5cae4a;
        }
        &:focus {
            background: #666666;
        }
        &:hover, &:active {
            background: darken(#fff, 10%);
        }
        &.btn-transparent {
            color: #666666;
            background: none;
            &:after {
                border-color: transparent transparent #5cae4a #5cae4a;
            }
            &:focus {
                color: #fff;
                background: none;
            }
            &:hover, &:active {
                background: #666666;
                border-color: #666666;
                color: #fff;
                &:after {
                    border-color: transparent transparent #5cae4a #5cae4a;
                }
            }
            &.btn-no-bd-top {
                position: relative;
                &:after {
                    border-color: transparent transparent $blue $blue;
                }
                &:before {
                    width: 50%;
                    height: 2px;
                    left: 25%;
                    position: absolute;
                    content: '';
                    top: -2px;
                    background: #ffffff;
                }
                &:hover {
                    &:before {
                        background: #666666;
                    }
                    &:after {
                        border-color: transparent transparent #fff #fff;
                    }
                }
            }
        }
    }
    &.btn-sm {
        line-height: 36px;
        padding-left: 30px;
        padding-right: 30px;
    }
    &.btn-xs {
        line-height: 32px;
        padding-left: 25px;
        padding-right: 25px;
    }
    &.btn-large {
        line-height: 51px;
        padding-left: 50px;
        padding-right: 50px;
        font-size: 18px;
    }
}