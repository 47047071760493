.footer {
    background: #fff;
    &.blue-footer {
        background: #506fa8;
        .footer-navigation {
            ul li a {
                color: #fff;
            }
        }
    }
    .footer-navigation {
        padding-top: 65px;
        ul {
            li {
                display: inline-block;
                vertical-align: middle;
                a {
                    color: #333333;
                    font-size: 11px; 
                    text-transform: uppercase;
                    font-family: 'Montserrat', sans-serif;
                    padding: 10px 5px 9px;
                    margin: 0 7px;
                    &:hover {
                        color: $green;
                    }
                }
            }
        }
    }
    .footer-social {
        background: #f5f5f5;
        padding: 45px 40px; 
        margin-right: 50px;
        position: relative;
        .scroll-top {
            display: block;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 0;
            right: -50px;
            background: url(../img/icons/top-arrow.png) center no-repeat $green;
        }
        .footer-title {
            font-family: Raleway, sans-serif;
            color: #333333;
            font-size: 14px;
            font-weight: 900;
            text-align: left;
            text-transform: uppercase;
            position: relative;
            padding-left: 40px;
            margin-bottom: 20px;
            &:before {
                content: "";
                height: 2px;
                width: 20px;
                background: #cccccc;
                position: absolute;
                top: 5px;
                left: 0;
            }
        }
        ul {
            padding-left: 35px;
            li {
                display: inline-block;
                vertical-align: middle;
                a {
                    color:  #838383;
                    font-size: 16px;
                    display: inline-block;
                    min-width: 20px;
                    margin: 0 6px;
                    i {
                        color:  #838383;
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .footer .footer-navigation ul li a {
        margin: 0 3px;
    }
}

@media (max-width: 991px) {
    .footer-navigation {
        margin-bottom: 30px;
        ul {
            text-align: center;
        }
    }
}

@media (max-width: 767px) {
    .footer .footer-social {
        padding: 15px;
    }
}