/*****************/
/***** HEADER ****/
/*****************/
.header {
    .navbar-brand {
        padding: 0 0 0 15px;
        height: auto;
    }
    &.scrolling-header {
        .navbar {
            &.navbar-default {
                &.navbar-scrolling, &.navbar-scrolling-fixing {
                    border-bottom: 1px solid #E4E4E4;
                    padding-top: 15px;
                    padding-bottom: 14px;
                }
            }
        }
    }
    .navbar {
        background: none;
        border: none;
        padding-top: 25px;
        padding-bottom: 25px;
        margin-bottom: 0;
        background: #ffffff;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0);
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        .toggle-button {
            line-height: 40px;
            cursor: pointer;
            color: #333;
            float: right;
            padding: 0 5px;
            &.collapsed {
                color: #999;
                &:hover {
                    color: #333;
                }
            }
        }
        .navbar-nav {
            &.social-nav {
                li {
                    a {
                        padding: 0 5px;
                    }
                }
            }
            > li {
                position: relative;
                &.social-icon {
                    &:first-child {
                        margin-left: 10px;
                    }
                    &:last-child {
                        margin-right: 10px;
                    }
                    a {
                        font-size: 14px;
                    }
                }
                &.active, &:hover {
                    a {
                        color: $green;
                        border-bottom-color: $green;
                    }
                }
                > a {
                    color: #333333;
                    font-size: 11px;
                    text-transform: uppercase;
                    font-family: 'Montserrat', sans-serif;
                    padding: 10px 5px 9px;
                    margin: 0 7px;
                    border-bottom: 1px solid transparent;
                    &:hover {
                        color: $green;
                        border-bottom-color: $green;
                    }
                    .fa-bars {
                        margin-right: 10px;
                    }
                }
            }
        }
        .toggle-menu-button {
            width: 32px;
            height: 32px;
            background: $blue;
            border-radius: 50%;
            text-align: center;
            padding: 6px 0 0 0 !important;
            border-bottom: 0 !important;
            &.is-open {
                padding-top: 4px !important;
            }
            &:hover, &:focus {
                background: $blue;
            }
            &.is-open {
                .toggle-menu-button-icon {
                    span {
                        background: #fff;
                        &:nth-child(1), &:nth-child(6) {
                            -webkit-transform: rotate(45deg);
                            -moz-transform: rotate(45deg);
                            -o-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                        &:nth-child(2), &:nth-child(5) {
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                        }
                        &:nth-child(1) {
                            left: 2px;
                            top: 4px;
                        }
                        &:nth-child(2) {
                            left: calc(50% - 2px);
                            top: 4px;
                        }
                        &:nth-child(3) {
                            left: -50%;
                            opacity: 0;
                        }
                        &:nth-child(4) {
                            left: 100%;
                            opacity: 0;
                        }
                        &:nth-child(5) {
                            left: 2px;
                            top: 11px;
                        }
                        &:nth-child(6) {
                            left: calc(50% - 2px);
                            top: 11px;
                        }
                    }
                }
            }
            .toggle-menu-button-icon {
                width: 16px;
                height: 17px;
                display: inline-block;
                vertical-align: middle;
                position: relative;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: .5s ease-in-out;
                -moz-transition: .5s ease-in-out;
                -o-transition: .5s ease-in-out;
                transition: .5s ease-in-out;
                cursor: pointer;
                span {
                    display: block;
                    position: absolute;
                    height: 2px;
                    width: 50%;
                    background: #eee;
                    opacity: 1;
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    transform: rotate(0deg);
                    -webkit-transition: .25s ease-in-out;
                    -moz-transition: .25s ease-in-out;
                    -o-transition: .25s ease-in-out;
                    transition: .25s ease-in-out;
                    &:nth-child(even) {
                        left: 50%;
                        border-radius: 0 9px 9px 0;
                    }
                    &:nth-child(odd) {
                        left: 0px;
                        border-radius: 9px 0 0 9px;
                    }
                    &:nth-child(1), &:nth-child(2) {
                        top: 2px;
                    }
                    &:nth-child(3), &:nth-child(4) {
                        top: 6px;
                    }
                    &:nth-child(5), &:nth-child(6) {
                        top: 10px;
                    }
                }
            }
        }

    }
}

.navbar-with-inside > li:hover .wrap-inside-nav {
    display: block;
}

.wrap-inside-nav {
    position: absolute;
    left: 0;
    top: 38px;
    display: none;
    min-width: 220px;
    text-align: left;
    border-top: 2px solid $green;
    background: #1a1a1a;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    .inside-col {
        padding: 15px 0;
        .inside-nav {
            &:after, &:before {
                display: table;
                clear: both;
                height: 0;
                content: '';
            }
            li {
                line-height: 45px;
                min-height: 45px;
                a {
                    display: block;
                    padding-left: 20px;
                    color: #7e848e !important;
                    font-size: 14px;
                    text-decoration: none;
                    font-weight: bold;
                    &:active, &:focus {
                        display: block;
                        padding-left: 20px;
                        color: $green !important;
                    }
                    &:hover, &:active:hover, &:visited:hover, &:focus:hover {
                        color: $green !important;
                        background-color: #26282d;
                    }
                }
            }
        }
    }
}

.wrap-fixed-menu {
    display: none;
    position: fixed;
    background: #333;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    overflow: auto;
    font-size: 0;
    .top-items {
        width: 100%;
        height: 50%;
        display: table;
        .menu-item {
            border-bottom: 1px solid #484848;
        }
    }
    .bottom-items {
        width: 100%;
        height: 50%;
        display: table;
    }
    .menu-item {
        font-size: 14px;
        text-align: center;
        width: 50%;
        height: 50%;
        position: relative;
        display: table-cell;
        vertical-align: middle;
        color: #fff;
        background: #32323C;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        text-decoration: none;
        &.contact-info {
            cursor: default;
            padding-left: 30px;
            padding-right: 30px;
            &:hover {
                background: #333;
            }
            h4 {
                font-size: 36px;
                font-weight: bold;
                color: #fff;
            }
            .fa {
                margin-right: 10px;
            }
        }
        &:hover {
            background: #484848;
        }
        &:first-child {
            border-right: 1px solid #484848;
        }
        .subtitle {
            font-size: 16px;
            margin-bottom: 10px;
        }
        .title {
            font-size: 36px;
            font-weight: bold;
            color: #fff;
            display: block;
        }
    }
}

@media (max-width: 991px) {
    .header .navbar .navbar-nav > li > a {
        margin: 0 4px;
    }
}

@media (max-width: 767px) {
    .header {
        .navbar {
            &.navbar-default {
                position: static !important;
                padding-bottom: 14px !important;
                padding-top: 15px !important;
                .navbar-brand {
                    margin-left: -15px;
                }
                .right-nav-block {
                    float: none !important;
                    margin-top: 40px;
                    .navbar-collapse {
                        border: none;
                        .navbar-nav {
                            float: none;
                            width: auto;
                            display: block;
                            margin-top: 0;
                            margin-bottom: 0;
                            li {
                                float: none;
                                display: block;
                                a {
                                    display: block;
                                    padding: 10px 0;
                                }
                                &.social-icon {
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .wrap-fixed-menu {
        display: none !important;
    }
    .wrap-inside-nav {
        position: static;
        margin-top: -1px;
    }
    .header .navbar.navbar-default .right-nav-block .navbar-collapse .navbar-nav .wrap-inside-nav a {
        padding: 5px 0 5px 10px;
    }
}