.radio {
    margin: 0;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    + .radio {
        margin: 0;
    }
    &.radio-default {
        input[type="radio"] {
            display: none;
            &:checked + span {
                &:before {
                    background: #2c2e30;
                    box-shadow: 0 0 0 4px #fff inset;
                }
            }
        }
        span {
            &:before {
                border-color: #d9d9d9;
            }
        }
    }
    &.radio-success {
        input[type="radio"] {
            display: none;
            &:checked + span {
                &:before {
                    background: $green;
                    box-shadow: 0 0 0 4px #fff inset;
                }
            }
        }
        span {
            &:before {
                border-color: #cecece;
            }
        }
    }
    input[type="radio"] {
        display: none;
        &:checked + span {
            &:before {
                border: none;
                background: #fff;
                box-shadow: 0 0 0 4px $blue inset;
            }
        }
    }
    span {
        display: inline-block;
        &:before {
            content: '';
            text-align: center;
            line-height: 19px;
            color: #ffffff;
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            border: 1px solid #3f5c91;
            margin-right: 10px;
            border-radius: 50%;
        }
    }
}