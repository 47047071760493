.progress {
    border: 1px solid #eeeeee;
    border-radius: 0;
    background: #fff;
    box-shadow: none;
    &.bordered {
        &.progress-primary {
            border: 2px solid #3f5c91;
        }
        &.progress-success {
            border: 2px solid #5cae4a;
        }
    }
    &.progress-primary {
        .progress-bar {
            background-color: #3f5c91;
        }
    }
    &.progress-warning {
        .progress-bar { 
            background-color: #f7ba16;
        }
    }
    &.progress-danger {
        .progress-bar {
            background-color: #ff2000;
        }
    }
    &.progress-custom {
        .progress-bar { 
            background-color: #ab08f0;
        }
    }
    &.progress-custom-2 {
        .progress-bar { 
            background-color: #d4159c;
        }
    }
    &.progress-success {
        .progress-bar { 
            background-color: #5cae4a;
        }
    }
    .progress-bar {
        box-shadow: none;
    }
}
