.service-features-item {
    margin-bottom: 30px;
    &.active {
        .item-icon {
            background: $blue;
            span {
                color: #ffffff;
            }
        }
    }
    .item-icon {
        margin-left: 35px;
        width: 85px;
        height: 85px;
        border: 1px solid #eeeeee;
        line-height: 85px;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        &:before {
            content: '';
            width: 50%;
            margin-left: -25%;
            top: -1px;
            left: 50%;
            background: #fff;
            height: 5px;
            position: absolute;

        }
        span {
            font-size: 24px;
            color: #666666;
        }
    }
    .item-heading {
        padding-left: 35px;
        .top-heading {
            font-size: 14px;
            font-family: "Lato", sans-serif;
            font-style: italic;
            margin-bottom: 15px;
        }
        h5 {
            text-transform: uppercase;
            margin-bottom: 30px;
            font-size: 16px;
            margin-left: -30px;
        }
    }
    p {
        padding-left: 35px;
    }
    
}