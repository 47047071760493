.comments {
    margin-bottom: 70px;
    .comment-title {
        text-transform: uppercase;
        margin-bottom: 10px;
    }
}

.comments-list {
    .comment-item {
        padding: 50px 0 35px;
        border-bottom: 1px solid #eeeeee;
        .comment-item {
            padding-left: 50px;
            padding-bottom: 0;
            &:last-child {
                border-bottom: none;
            }
        }
        .media-left {
            padding-right: 15px;
        }
        .avatar {
            width: 80px;
            height: 80px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .comment-content {
            position: relative;
            padding-left: 35px;
            padding-top: 10px;
            &:before {
                content: '';
                background-color: #ccc;
                width: 20px;
                height: 2px;
                position: absolute;
                left: 0;
                top: 15px;
            }
            .name {
                font-family: Raleway, sans-serif;
                color: #333333;
                font-size: 14px;
                font-weight: 700;
                padding-right: 100px;
            }
            .date {
                color: #999999;
                font-size: 14px;
                font-weight: 300;
                font-style: italic;
                line-height: 22px;
                padding-right: 100px;
            }
            .reply-btn {
                position: absolute;
                right: 0;
                top: 0;
                border: 2px solid #666666;
                line-height: 31px;
                padding: 0 15px;
                &:after {
                    border-color: transparent transparent $green $green;
                }
            }
            .text {
                padding-top: 22px;
                p {
                    color: #666666;
                    font-size: 14px;
                    line-height: 26px;
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .comments-list {
        .comment-item {
            .avatar {
                width: 40px;
                height: 40px;
            }
            .comment-item {
                padding-left: 15px;
            }
        }
    }
}


@media (max-width: 500px) {
    .comments-list {
        .comment-item {
            .comment-item {
                padding-left: 0;
            }
            .comment-content {
                padding-left: 0;
                &:before {
                    display: none;
                }
            }
        }
    }
}
