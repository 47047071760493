.social-list  {
    li {
        display: inline-block;
        vertical-align: middle;
        padding-left: 8px;
        padding-right: 8px;
        a {
            color: #666666;
            text-decoration: none;
            font-size: 16px;
        }
    }
}