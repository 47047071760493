// FORMS
.input-group {
    .form-control {
        height: 52px;
        background: #fff;
        &:first-child {
            padding-left: 15px;
            border-radius: 3px 0 0 3px;
        }
    }
}

.form-group {
    &.has-feedback {
        .form-control-feedback {
            line-height: 40px;
            height: 40px;
            color: $green;
        }
        .form-control {
            padding-right: 45px;
        }
    }
}

textarea {
    max-width: 100%;
    &.form-control {
        margin-bottom: 40px;
        min-height: 100px;
        resize: none;
    }
}

.white-form {
    .form-control {
        border: 1px solid #dddddd;
        font-family: "Lato", sans-serif;
        font-style: italic;
        color: #333;
        &::-webkit-input-placeholder {
            color: #666666;
        }
        &:-moz-placeholder {
            color: #666666;
            opacity: 1;
        }
        &::-moz-placeholder {
            color: #666666;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: #666666;
            opacity: 1;
        }
    }
}

.form-control {
    background: none;
    box-shadow: none !important;
    outline: none;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-family: Lato, sans-serif;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    height: 50px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    &::-webkit-input-placeholder {
        color: #c4cee4;
    }
    &:-moz-placeholder {
        color: #c4cee4;
        opacity: 1;
    }
    &::-moz-placeholder {
        color: #c4cee4;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: #c4cee4;
        opacity: 1;
    }
    &:focus, &:active {
        border-color: #dddddd;
    }
    &.bordered {
        border: 1px solid #ddd;
        border-radius: 2px;
        color: #777777;
        font-style: italic;
        padding: 0 15px;
    }
}

.form-control-feedback {
    /*top: 8px;*/
} 
