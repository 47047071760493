/*------------------------------------------------------------------
Project:	 AKLAS
Version: 	1.0
Assigned to:	Alximicus
Primary use:	AKLAS
-------------------------------------------------------------------*/

/* RESET */
@import "_reset.scss";

/*************************/
/***** BASE STYLES *******/
/*************************/

@import "_base.scss";

/************************/
/*        LAYOUT        */


/******** HEADER *********/
@import "layout/_header.scss"; 

/******** FOOTER *********/
@import "layout/_footer.scss";

/******** SIDEBAR ********/
@import "layout/_sidebar.scss";



/*********  SECTIONS **********/

@import "_sections.scss";


/************************/
/*        MODULES       */

/******** LOADER ********/
@import "modules/_loader.scss"; 

/******** BUTTONS ********/
@import "modules/_buttons.scss";

/******** ALERTS ********/
@import "modules/_alerts.scss";

/******** FORMS ********/
@import "modules/_forms.scss"; 

/******** Welcome feature ********/
@import "modules/_welcome-feature.scss"; 

/******** Special info item ********/
@import "modules/_special-info-item.scss"; 

/******** Doing tabs ********/
@import "modules/_doing-tabs.scss"; 

/******** Project carousel ********/
@import "modules/_projects-carousel.scss";

/******** Gallery item ********/
@import "modules/_gallery-item.scss";

/******** Project tabs ********/
@import "modules/_projects-tabs.scss"; 

/******** choose-item ********/
@import "modules/_choose-item.scss"; 

/******** skills ********/
@import "modules/_skills.scss"; 

/******** Comments ********/
@import "modules/_comments-carousel.scss"; 

/******** News item ********/
@import "modules/_news-item.scss";

/******** Blog item ********/
@import "modules/_blog-item.scss";

/******** Pagintaion ********/
@import "modules/_pagination.scss";

/******** Sidebar widgget ********/
@import "modules/_sidebar-widget.scss";

/******** Working line ********/
@import "modules/_working-line.scss";

/******** Fancybox ********/
@import "modules/_fancybox.scss";

/******** Load more button ********/
@import "modules/load-more-button";

/******** Blockquote article ********/
@import "modules/blockquote-article";

/******** Comments ********/
@import "modules/comments";

/******** Comment form ********/
@import "modules/comment-form";

/******** Coming soon ********/
@import "modules/coming-soon";

@import "modules/section-heading";
@import "modules/checkbox";
@import "modules/radio";
@import "modules/progress";
@import "modules/service-features-item";
@import "modules/portfolio-item";
@import "modules/price-item";
@import "modules/social-list";
@import "modules/counters";



// Theme styles
@import "_theme.scss";


/****** DEMO PAGE ******/
@import "_demo.scss"; 

/*MEDIA*/ 
@import "_media.scss";











