// Typography
h1 {
    font-size: 54px;
    font-family: 'Raleway', sans-serif;
    margin: 0 0 0.5em;
    font-weight: 300;
}

h2 {
    font-size: 46px;
    font-family: 'Raleway', sans-serif;
    margin: 0 0 0.5em;
    font-weight: 300;
}

h3 {
    font-size: 38px;
    font-family: 'Raleway', sans-serif;
    margin: 0 0 0.5em;
    font-weight: 300;
}

h4 {
    font-size: 24px;
    font-family: 'Raleway', sans-serif;
    margin: 0 0 0.5em;
    font-weight: 300;
}

h5 {
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
    margin: 0 0 0.5em;
    font-weight: 300;
}

h6 {
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    margin: 0 0 0.5em;
    font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
    &.bold {
        font-weight: bold;
    }
    &.with-underline {
        &.short-underline {
            &:after {
                width: 20px;
            }
        }
        &.under-green {
            &:after {
                border-color: $green;
            }
        }
        &.under-gray {
            &:after {
                border-color: #ccc;
            }
        }
        &.under-blue {
            &:after {
                border-color: $blue;
            }
        }
        &.under-center {
            display: inline-block;
            &:after {
                margin: 20px auto 0.5em;
            }
        }
        &.left-line {
            &:after {
                display: none;
            }
            &:before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 20px;
                margin-right: 10px;
                border-bottom: 2px solid #cccccc;
            }
        }
        &:after {
            content: "";
            display: block;
            width: 60px;
            max-width: 100%;
            border-bottom: 2px solid $blue;
            margin-top: 20px;
            margin-bottom: 0.5em;
        }
    }
}

.bold {
    font-weight: bold;
}

a {
    &.green {
        &:hover {
            color: $green;
        }
    }
    &:hover {
        text-decoration: none;
    }
}

p {
    margin-bottom: 0.5em;
    font-family: 'Lato', sans-serif;
    color: #666666;
    font-size: 14px;
    line-height: 28px;
    span {
        
        &.hightlited-blue,
        &.hightlited-green {
            padding: 3px 5px;
            color: #fff;
        }
        &.hightlited-blue {
            background: $blue;
        }
        &.hightlited-green {
            background: $green;
        }
        &.hightlited-dotted {
            border: 1px dotted #777777;
            color: $blue;
            padding: 0 5px;
        }
    }
    .first-letter {
        font-size: 48px;
        width: 60px;
        font-weight: bold;
        text-align: center;
        line-height: 60px;
        margin: 8px 15px 15px 0;
        float: left;
        text-transform: uppercase;
        &.circle {
            border-radius: 50%;
        }
        &.blue-first-letter {
            background: #237ac6;
            color: #fff;
        }
        &.square {
            font-size: 32px;
            line-height: 40px;
            height: 40px;
            width: 40px;
        }
        &.green-first-letter {
            background: $green;
            color: #fff;
        }
    }
}

// Blockqoutes
blockquote {
    display: block;
    &.blockquote-1 {
        font-size: 16px;
        font-family: Merriweather, sans-serif;
        font-style: italic;
        color: #777777;
        line-height: 1.75;
        text-align: center;
        position: relative;
        margin-bottom: 60px;
        &:before {
            content: '';
            width: 100px;
            height: 40px;
            background: url(../img/blockquote-1.png) center no-repeat;
            display: block;
            margin: 0 auto 30px auto;
            
        }
    }
    &.blockquote-2 {
        font-size: 20px;
        font-family: Merriweather, sans-serif;
        font-style: italic;
        color: #333333;
        line-height: 1.75;
        margin-bottom: 60px;
        padding-left: 120px;
        padding-right: 30px;
        position: relative;
        text-align: left;
        &:before {
            content: '';
            width: 60px;
            height: 100px;
            background: url(../img/blockquote-2.png) center no-repeat;
            display: block;
            margin: -50px 0 0 0;
            position: absolute;
            left: 30px;
            top: 50%;
        }
    }
    &.blockquote-3 {
        font-size: 24px;
        font-family: Merriweather, sans-serif;
        font-style: italic;
        color: #333333;
        line-height: 1.75;
        margin-bottom: 60px;
        padding-left: 40px;
        position: relative;
        text-align: left;
        border-left: 4px solid $green;
        a {
            display: block;
            line-height: 1;
            color: #333333;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
            font-family: "Lato", sans-serif;
            margin: 30px 0 0 0;
            font-style: normal;
        }
        span {
            display: block;
            margin: 0;
            line-height: 1;
            font-size: 14px;
            color: #666666;
            font-weight: 300;
        }
    }
    &.blockquote-4 {
        font-size: 16px;
        font-family: Merriweather, sans-serif;
        font-style: italic;
        color: #fff;
        line-height: 1.75;
        margin-bottom: 60px;
        padding: 40px 50px 40px 140px;
        position: relative;
        text-align: left;
        border-left: 4px solid $green;
        background: $blue;
        &:before {
            content: '';
            width: 35px;
            height: 22px;
            background: url(../img/blockquote-3.png) center no-repeat;
            position: absolute;
            top: 45px;
            left: 65px;
        }
        a {
            display: block;
            line-height: 1;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
            font-family: "Lato", sans-serif;
            margin: 0;
            font-style: normal;
        }
        span {
            display: block;
            margin: 0 0 30px;
            line-height: 1;
            font-size: 14px;
            color: #fff;
            font-weight: 300;
        }
    }
    //&.blockquote-free {
    //    padding: 15px 0 15px 70px;
    //    font-size: 26px;
    //    color: #333;
    //    font-family: 'Merriweather', serif;
    //    font-style: italic;
    //    line-height: 36px;
    //    margin-bottom: 60px;
    //    .author {
    //        font-size: 14px;
    //        font-weight: bold;
    //        font-family: Lato, sans-serif;
    //        color: $green;
    //        text-transform: uppercase;
    //        font-style: normal;
    //    }
    //    &.green-blockquote {
    //        background: url(../img/icons/quotes-green.png) top left no-repeat;
    //    }
    //}
    //&.blockquote-box {
    //    padding: 35px 15px 35px 90px;
    //    font-size: 17px;
    //    color: #000000;
    //    font-family: 'Karla', serif;
    //    font-style: italic;
    //    line-height: 28px;
    //    margin-bottom: 60px;
    //    background: #f6f6f6;
    //    border-left: 5px solid $green;
    //    &.text-left {
    //        text-align: left;
    //    }
    //    &.blue-blockquote {
    //        background: url(../img/icons/quotes-blue.png) 35px 30px no-repeat;
    //        background-color: #f6f6f6;
    //    }
    //    &.white-blockquote {
    //        background-color: #fff;
    //    }
    //}
    //&.blockquote-full {
    //    padding: 50px 25px 50px 135px;
    //    font-size: 16px;
    //    color: #fff;
    //    font-family: 'Karla', serif;
    //    font-weight: bold;
    //    line-height: 30px;
    //    margin-bottom: 60px;
    //    background-color: #237ac6;
    //    &.blue-blockquote {
    //        background: url(../img/icons/quotes-green-circle.png) 34px 59px no-repeat;
    //        background-color: #237ac6;
    //    }
    //}
}

.blockquote-box {
    text-align: right;
    margin-bottom: 20px;
    &.white-box {
        blockquote {
            background: url(../img/icons/quotes-green.png) 40px 40px no-repeat;
            background-color: #fff;
            background-size: 25px 20px;
            &:after {
                border-left-color: #fff;
            }
        }
        .wrap-author {
            .avatar {
                border: 5px solid #fff;
            }
        }
    }
    blockquote {
        text-align: left;
        padding: 40px 20px 40px 80px;
        font-family: Karla, sans-serif;
        color: #333333;
        font-style: italic;
        line-height: 28px;
        position: relative;
        margin-bottom: 30px;
        background: url(../img/icons/quotes-green.png) 40px 40px no-repeat;
        background-color: #f6f6f6;
        background-size: 25px 20px;
        &:after {
            content: "";
            border: 20px solid transparent;
            border-left-color: #f6f6f6;
            display: block;
            position: absolute;
            top: 100%;
            right: 30px;
            margin-top: -20px;
        }
    }
    .wrap-author {
        display: inline-block;
        text-align: left;
        .avatar {
            overflow: hidden;
            width: 60px;
            height: 60px;
            border: 5px solid #f6f6f6;
            border-radius: 50%;
            background: #fff;
        }
        .author {
            padding-left: 10px;
            a {
                display: block;
                font-family: Lato, sans-serif;
                color: #237ac6;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
            }
            span {
                font-family: Karla, sans-serif;
                color: #777777;
                font-size: 13px;
                font-style: italic;
                text-align: left;
            }
        }
    }
}

.content-title {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    padding: 25px 10px 25px 25px;
    border-left: 10px solid $green;
    margin-bottom: 35px;
    &:before {
        content: "";
        width: 130px;
        height: 10px;
        background: $green;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    &:after {
        content: "";
        width: 230px;
        height: 10px;
        background: $green;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    span {
        display: block;
        line-height: 60px;
    }
    .top {
        font-family: Raleway, sans-serif;
        color: #3f5c91;
        font-size: 46px;
        font-weight: 700;
    }
    .bottom {
        font-family: Raleway, sans-serif;
        color: #333333;
        font-size: 46px;
        font-weight: 700;
    }
}

.contnet-under-title {
    font-family: Lato, sans-serif;
    color: #333333;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 35px;
    text-transform: uppercase;
}

.green {
    color: $green;
    + .tooltip {
        .tooltip-inner {
            background: $green;
            color: #fff;
            text-transform: uppercase;
            border-radius: 0;
        }
        .tooltip-arrow {
            border-top-color: $green;
        }
    }
}

.blue {
    color: $blue;
    + .tooltip {
        .tooltip-inner {
            background: $blue;
            color: #fff;
            text-transform: uppercase;
            border-radius: 0;
        }
        .tooltip-arrow {
            border-top-color: $blue;
        }
    }
}

.grey {
    color: $grey;
}

.dark {
    position: relative;
    &.dark-strong {
        &:after {
            background: #0b0f12;
            opacity: 0.9;
        }
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #191919;
        opacity: 0.72;
        z-index: 1;
    }
}

.blue-dark {
    position: relative;
    &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $blue;
        opacity: 0.95;
        z-index: 1;
    }
    &.solid {
        &:after {
            opacity: 1;
        }
    }
}

.green-dark {
    position: relative;
    &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $green;
        opacity: 0.95;
        z-index: 1;
    }
}

.dark-content {
    position: relative;
    z-index: 2;
}

.light-title {
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: 100;
    font-family: 'Open Sans', sans-serif;
    color: #777777;
}

ul, ol {
    &.list {
        margin-bottom: 30px;
        &.list-round-check {
            &.blue-list {
                li {
                    &:before {
                        color: $blue;
                    }
                }
            }
            li {
                &:before {
                    font-family: FontAwesome;
                    content: "\f05d";
                    color: #111;
                    margin-right: 20px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
        &.list-caret-right {
            li {
                &:before {
                    font-family: FontAwesome;
                    content: "\f0da";
                    color: $green;
                    margin-right: 20px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
        &.list-check {
            li {
                &:before {
                    font-family: FontAwesome;
                    content: "\f00c";
                    color: $blue;
                    margin-right: 20px;
                    display: inline-block;
                    vertical-align: middle;
                }
                &.disable {
                    &:before {
                        content: "\f00d";
                        color: $green;
                    }
                }
            }
        }
        &.list-pointer-right {
            li {
                &:before {
                    font-family: FontAwesome;
                    content: "\f0a4";
                    color: $green;
                    margin-right: 20px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
        &.list-dash {
            li {
                &:before {
                    content: "";
                    width: 20px;
                    margin-right: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    border-bottom: 2px solid #ccc;
                }
            }
        }
        li {
            line-height: 30px;
            color: #333333;
            font-size: 15px;
            text-align: left;
        }
    }
    &.list-counter {
        counter-reset: item;
        li {
            line-height: 30px;
            color: #333333;
            font-size: 15px;
            text-align: left;
            &:before {
                content: counter(item) ". ";
                counter-increment: item;
                margin-right: 20px;
                display: inline-block;
                vertical-align: middle;
                color: $green;
            }
        }
        &.list-counter-circle {
            li {
                &:before {
                    content: counter(item);
                    color: #fff;
                    background: #111;
                    border-radius: 50%;
                    line-height: 20px;
                    width: 20px;
                    text-align: center;
                }
            }
        }
    }
    
}

.table {
    thead {
        tr {
            th {
                text-transform: uppercase;
                font-family: Lato, sans-serif;
                color: #333333;
                font-size: 14px;
                font-weight: 900;
                text-align: left;
                line-height: 30px;
                padding: 12px 15px;
                border: none;
                border-bottom: 4px solid #3f5c91;
                &:first-child {
                    text-indent: 7px;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                font-family: Karla, sans-serif;
                color: #333333;
                font-size: 15px;
                font-weight: 400;
                line-height: 25px;
                text-align: left;
                border: none;
                border-bottom: 1px solid #eeeeee;
                padding: 10px 15px;
            }
        }
    }
    &.table-lined-headers {
        thead {
            tr {
                th {
                    position: relative;
                    &:after {
                        content: '';
                        width: 20px;
                        height: 2px;
                        background: #cccccc;
                        display: block;
                    }
                }
            }
        }
    }
    &.table-warning {
        thead {
            tr {
                th {
                    border-bottom: 4px solid $green;
                }
            }
        }
    }
    &.table-dark-header {
        thead {
            tr {
                th {
                    background: #f5f5f5;
                    border-top: 1px solid #eeeeee;
                }
            }
        }
    }
}

// Half effect
.half-effect {
    position: relative;
    overflow: hidden;
    &:after {
        content: "";
        position: absolute;
        top: -100%;
        right: -53%;
        bottom: -100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.1);
        transform: rotate(20deg);
    }
}
.half-effect-reverse {
    position: relative;
    overflow: hidden;
    &:after {
        content: "";
        position: absolute;
        top: -100%;
        left: -53%;
        bottom: -100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.1);
        transform: rotate(-20deg);
    }
}

.half-color {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
        width: 50%;
    }
    &.right-color {
        &:before {
            right: 0;
        }
    }
    &.left-color {
        &:before {
            left: 0;
        }
    }
    &.blue-color {
        &:before {
            background: $blue;
        }
    }
}

.blue-col {
    background: $blue;
}

@media (max-width: 991px) {
    .content-title {
        span {
            line-height: 42px;
        }
        .top {
            font-size: 36px;
        }
        .bottom {
            font-size: 36px;
        }
    }
    .contnet-under-title {
        font-size: 22px;
    }
}