.working-line {
    margin-top: -50px;
    .line {
        height: 200px;
        //width: 853px;
        display: block;
        margin: 0 auto;
        background: url(../img/line.svg) center no-repeat;
        background-size: contain;
        position: relative;
        font-size: 0;
        .working-item {
            display: inline-block;
            vertical-align: middle;
            width: 25%;
            font-size: 14px;
            position: absolute;
            text-align: center;
            &.first-item {
                left: 0;
                top: 67%;
            }
            &.second-item {
                left: 25%;
                top: 49%;
                .item-dot {
                    margin-bottom: 10px;
                }
                .item-description {
                    margin-bottom: 50px;
                }
            }
            &.third-item {
                right: 25%;
                padding-right: 45px;
                top: 7%;
                .item-dot {
                    margin-bottom: 50px;
                }
            }
            &.fourth-item {
                right: 0;
                top: -28%;
                .item-icon {
                    padding-left: 40px;
                    i {
                        transform: rotate(-15deg);
                        display: inline-block;
                    }
                }
                .item-description {
                    text-align: right;
                    .title {
                        position: absolute;
                        left: -32px;
                        top: 18px;
                    }
                    .text {
                        position: absolute;
                        right: -25px;
                        top: 85px;
                        text-align: left;
                    }
                }
            }

            .item-icon {
                color: #666666;
                font-size: 32px;
                margin-bottom: 10px;
            }
            .item-dot {
                border-radius: 50%;
                background-color: #ffffff;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
                width: 22px;
                height: 22px;
                position: relative;
                margin: 0 auto 20px;
                &:before {
                    content: '';
                    border-radius: 50%;
                    background-color: $green;
                    width: 12px;
                    height: 12px;
                    top: 5px;
                    left: 5px;
                    position: absolute;
                }
            }
            .item-description {
                .title {
                    font-family: Raleway, sans-serif;
                    color: #333333;
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                }
                .text {
                    color: #666666;
                    font-size: 14px;
                    font-weight: 300;
                    font-style: italic;
                    line-height: 22px;
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .working-line .line .working-item {
        &.first-item {
            text-align: left;
            padding-left: 20px;
            .item-dot {
                margin-left: 5px;
                margin-right: 0;
            }
        }
        &.second-item {
            padding-right: 20px;
            left: 23%;
        }
        &.third-item {
            padding-right: 0;
        }
        &.fourth-item {
            text-align: right;
            .item-icon {
                padding-right: 5px;
            }
            .item-dot {
                margin-right: 30px;
            }
            .item-description {
                .title {
                    text-align: right;
                    left: auto;
                    right: 65px;
                    top: 10px;
                }
                .text {
                    text-align: right;
                    left: auto;
                    right: 65px;
                    top: 44px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .working-line {
        margin-top: 30px;
        .line {
            background: none;
            height: auto;
            display: flex;
            flex-flow: row wrap;
            .working-item {
                display: block;
                position: static;
                width: 50%;
                text-align: center;
                margin-bottom: 30px;
                .item-description {
                    margin: 0 !important;
                }
                &.first-item {
                    text-align: center;
                    padding: 0;
                    .item-dot {
                        margin: 0 auto 15px;
                    }
                }
                &.second-item {
                    text-align: center;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    flex-direction: column-reverse;
                    .item-dot {
                        margin: 0 auto 15px;
                    }
                }
                &.third-item {
                    text-align: center;
                    padding: 0;
                    .item-dot {
                        margin: 0 auto 15px;
                    }
                }
                &.fourth-item {
                    text-align: center;
                    padding: 0;
                    .item-dot {
                        margin: 0 auto 15px;
                    }
                    .item-icon {
                        padding: 0;
                        i {
                            transform: rotate(0);
                        }
                    }
                    .item-description {
                        text-align: center;
                        .title {
                            position: static;
                            text-align: center;
                        }
                        .text {
                            position: static;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .working-line {
        .line {
            display: block;
            .working-item {
                display: block;
                width: 100%;
            }
        }
    }
}