.pagination {
    ul {
        li {
            float: none;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
            margin-bottom: 10px;
            &:hover {
                a {
                    background: $green;
                    color: #ffffff;
                    &:before, &:after {
                        background: none;
                    }
                }
            }
            &.divider {
                height: 2px;
                width: 20px;
                background: #cccccc;
                margin: 0 10px 10px 5px;
            }

            a {
                width: 42px;
                height: 36px;
                display: block;
                border: 1px solid #e5e5e5;
                border-top: none;
                position: relative;
                line-height: 36px;
                text-align: center;
                color: #666666;
                font-size: 12px;
                &:before {
                    content: "";
                    width: 10px;
                    height: 1px;
                    background: #e5e5e5;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &:after {
                    content: "";
                    width: 10px;
                    height: 1px;
                    background: #e5e5e5;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
            span {
                height: 36px;
                width: 42px;
                display: block;
                border: 1px solid #e5e5e5;
                border-top: none;
                position: relative;
                line-height: 36px;
                text-align: center;
                color: #666666;
                font-size: 12px;
                &.left-arrow {
                    background: url(../img/icons/left-arrow.png) center no-repeat;
                }
                &.right-arrow {
                    background: url(../img/icons/right-arrow.png) center no-repeat;
                }
            }
            a {
                &:hover {
                }
            }
            &.active a, &.active span {
                background: $green;
                color: #fff;
                border-color: $green;
                &:before, &:after {
                    background: $green;
                }
            }
        }
    }
}