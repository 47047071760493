.portfolio-item {
  margin-bottom: 20px;
  .item-icon {
    margin-bottom: 30px;
    color: $blue;
    font-size: 32px;
    margin-left: 40px;
  }
  .item-heading {
    padding-left: 40px;
    .top-heading {
      font-size: 14px;
      font-family: "Lato", sans-serif;
      font-style: italic;
      margin-bottom: 15px;
    }
    h5 {
      text-transform: uppercase;
      font-size: 16px;
      margin-bottom: 20px;
      margin-left: -30px;
      &.with-underline.left-line:before {
        border-color: $green;
      }
    }
  }
  p {
    padding-left: 40px;
  }
}