.skills {
    padding-left: 40px;
    padding-bottom: 30px;
    li {
        position: relative;
        height: 4px;
        margin-top: 75px;
        background-color: #dddddd;
        > span {
            font-weight: 700;
            position: absolute;
            z-index: 1;
            top: -20px;
            left: 0;
            font-family: Montserrat, sans-serif;
            color: #333333;
            font-size: 12px;
            text-transform: uppercase;
        }
        .progress {
            position: absolute;
            top: 0;
            left: 0;
            overflow: visible!important;
            width: 0;
            height: 100%;
            -webkit-transition: width 1.2s ease-in-out 0;
            -o-transition: width 1.2s ease-in-out 0;
            transition: width 1.2s ease-in-out 0;
            background-color: $blue;
        }
        .progress-percent {
            font-size: 11px;
            line-height: 20px;
            position: absolute;
            top: -28px;
            right: 0;
            height: 20px;
            padding: 0 9px;
            color: $green;
            background: #fff;
            font-size: 12px;
            font-weight: 700;
            text-align: left;
            border: 1px solid #dddddd;
            margin-right: -15px;
            &:before {
                position: absolute;
                bottom: -4px;
                content: '';
                pointer-events: none;
                left: 50%;
                margin-left: -3px;
                width: 6px;
                height: 6px;
                background: #fff;
                border-right: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                transform: rotate(45deg);
            }
        }
    }
}