.projects-tabs {
    margin-bottom: 50px;
    text-align: center;
    border: none;
    li {
        float: none;
        display: inline-block;
        margin-bottom: 0;
        background: none !important;
        &:hover, &.active {
            a {
                border: none !important;
                border-bottom: 1px solid $green !important;
                color: #ffffff !important;
            }
        }
        a {
            background: none !important;
            border: none;
            border-radius: 0;
            box-shadow: none;
            font-family: Montserrat, sans-serif;
            color: #c4cee4;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            padding: 15px 7px;
            margin: 0 7px;
            border-bottom: 1px solid transparent;
        }
    }
}