.top-demo {
    padding: 100px 0;
    background: url(../img/sections/home-section-1.jpg) top center no-repeat;
    background-size: cover;
    .top-part {
        img {
            margin-bottom: 50px;
        }
    }
    border-bottom: 5px solid $green;
} 
.demopage-preview {
    padding: 100px 0;
}
.demopage-preview > div {
    padding-bottom: 70px;
}
.demopage-preview_item {
    background-image: url("../img/demo/mac.png");
    display: block;
    height: 314px;
    margin: 0 auto;
    padding: 17px 18px 85px 16px;
    width: 370px;
    + h3 {
        font-size: 16px;
        line-height: 120%;
        font-weight: bold;
        color: #666;
        padding: 53px 0 29px;
        text-align: center;
    }
}
.demopage-preview_inner {
    float: left;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.demopage-preview_inner img {
    left: 0;
    position: absolute;
    top: 0;
    transition: all 4s ease 0s;
    width: 100%;
}

.demopage-footer {
    background-color: #333;
    padding: 46px 0;
    text-align: center;
    span {
        color: #fff;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
    }
}