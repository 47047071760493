.price-item {
  display: block;
  margin: 0 auto 30px;
  max-width: 300px;
  text-align: center;
  padding: 50px 15px;
  border: 1px solid #dddddd;
  position: relative;
  &.active {
    border-color: $green;
    background: $green;
    .item-heading {
      h4 {
        color: #ffffff;
      }
      .under-heading {
        color: #ffffff;
      }
    }
    .count {
      color: #ffffff;
      i, span {
        color: #ffffff;
      }
      .count-border {
        border-color: #fff;
        &:after {
          background: #fff;
        }
      }
    }
    .separate {
      background: #79bb6b;
    }
    .list-features {
      li {
        color: #fff;
      }
    }
    .btn.btn-gray {
      color: #ffffff;
      border-color: #fff;
      &:hover {
        background: #ffffff;
        color: #333;
        &:after {
          border-color: transparent transparent #333 #333;
        }
      }
      &:after {
        border-color: transparent transparent #fff #fff;
      }
    }

  }
  &:before {
    content: '';
    width: 80%;
    left: 10%;
    height: 5px;
    top: -1px;
    background: #fff;
    position: absolute;
  }
  .item-heading {
    margin-bottom: 50px;
    h4 {
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
      font-family: "Raleway", sans-serif;
      color: #333333;
      margin: 0 0 5px;
    }
    .under-heading {
      font-size: 14px;
      font-style: italic;
      font-family: "Lato", sans-serif;
      color: #666666;
    }
  }
  .count {
    position: relative;
    margin-bottom: 30px;
    padding-left: 25px;
    span {
      font-size: 44px;
      font-family: "Lato", sans-serif;
      font-weight: bold;
    }
    i {
      font-size: 14px;
      font-family: "Lato", sans-serif;
      font-style: italic;
      font-weight: 300;
      color: #666666;
    }
    .count-border {
      position: absolute;
      height: 40px;
      width: 140px;
      border-bottom: 3px solid $green;
      border-left: 3px solid $green;
      left: 55px;
      top: 27px;
      &:after {
        content: '';
        right: 0;
        bottom: 0;
        width: 3px;
        height: 20px;
        position: absolute;
        background: $green;
      }
    }
  }
  .separate {
    height: 1px;
    background: #dddddd;
    margin: 60px -15px;
  }
  .list-features {
    margin-bottom: 50px;
    li {
      line-height: 20px;
      padding-top: 7px;
      padding-bottom: 7px;
      font-family:"Lato", sans-serif;
      color: #666666;
    }
  }
}