.doing-tabs {
    .nav {
        &.nav-tabs {
            text-align: center;
            border: none;
            margin-bottom: 50px;
            li {
                float: none;
                display: inline-block;
                vertical-align: top;
                margin: 0;
                &.active, &:hover {
                    a {
                        background: $green;
                        border-color: $green;
                        &:before {
                            border-top: 1px solid $green;
                            border-bottom: 3px solid $green;
                            background: $green;
                        }
                        &:after {
                            border-top: 1px solid $green;
                            border-bottom: 3px solid $green;
                            background: $green;
                        }
                    }
                }
                a {
                    background: none;
                    border: none;
                    border-radius: 0;
                    border-left: 1px solid transparent;
                    border-right: 1px solid transparent;
                    border-bottom: 1px solid transparent;
                    position: relative;
                    padding: 34px 20px 30px;
                    text-align: center;
                    color: #fff;
                    font-size: 32px;
                    margin-bottom: 35px;
                    span {
                        display: block;
                        width: 40px;
                        height: 40px;
                    }
                    &:before {
                        content: "";
                        width: 25%;
                        position: absolute;
                        top: -3px;
                        left: -1px;
                        height: 3px;
                        display: block;
                        border-top: 1px solid transparent;
                        border-left: 1px solid transparent;
                    }
                    &:after {
                        content: "";
                        width: 25%;
                        position: absolute;
                        top: -3px;
                        right: -1px;
                        height: 3px;
                        display: block;
                        border-top: 1px solid transparent;
                        border-right: 1px solid transparent;
                    }
                }
            }
        }
    }
    .tab-content {
        .tab-pane {
            .tab-heading {
                font-family: Raleway, sans-serif;
                color: #ffffff;
                font-size: 20px;
                font-weight: 500;
                text-transform: uppercase;
                position: relative;
                &:after {
                    content: "";
                    width: 40px;
                    height: 2px;
                    display: block;
                    margin: 20px auto 40px;
                    background: $green;
                }
            }
            .tab-text {
                font-family: Lato, sans-serif;
                color: #c4cee4;
                font-size: 14px;
                font-weight: 400;
                line-height: 28px;
            }
        }
    }
}

@media (max-width: 767px) {
    .doing-tabs .nav.nav-tabs {
        margin-bottom: 10px;
    }
}