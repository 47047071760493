/* Top section */
.header-section {
    padding: 180px 0 100px;
    height: 440px;
    text-align: left;
    p {
        color: #fff;
    }
    &.elements-header {
        background: url(../img/sections/section-1.jpg) top center no-repeat;
        background-size: cover;
    }
    &.home-header {
        background: url(../img/sections/home-section-1.jpg) top center no-repeat;
        background-size: cover;
        min-height: 800px;
        height: 100vh;
        display: flex;
        align-items: center;
        padding: 0;
        position: relative;
        .scroll-to {
            display: block;
            width: 40px;
            height: 20px;
            background: url(../img/icons/scroll-icon.png) top center no-repeat;
            position: absolute;
            bottom: 80px;
            left: 50%;
            margin-left: -20px;
            opacity: 0.2;
            z-index: 2;
        }
        &.home-header-2 {
            background: url(../img/sections/home-section-2.jpg) top center no-repeat;
            background-size: cover;
        }
    }
    
}

//Typography page

// Section headings
.section-headings {
    padding: 110px 0 80px;
    background: #fff;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 3px;
    h1, h2, h3, h4, h5 {
        margin-bottom: 40px;
    }
}

// Section texts
.section-texts {
    padding: 80px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 3px;
}

//Section lists
.section-lists {
    padding: 80px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 3px;
}

// blockquotes section
.section-blockquotes {
    padding: 80px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 3px;
    .light-title {
        margin-bottom: 70px;
    }
}

//Buttons section
.section-buttons {
    padding: 80px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 3px;
    .buttons-row {
        padding: 17px 0;
        .btn {
            margin: 0 10px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

//Buttons section
.section-pagination {
    padding: 80px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 3px;
}

//Buttons section
.section-forms {
    padding: 80px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 3px;
}

//Alerts section
.section-alerts {
    padding: 80px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 3px;
}

//bars section
.section-bars {
    padding: 80px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 3px;
}

//tables section
.section-tables {
    padding: 80px 0;
    border-top: 1px solid #eee;
}

.welcome-section {
    padding: 80px 0;
    .welcome-heading {
        font-family: Raleway, sans-serif;
        color: #333333;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    .welcome-text {
        padding-left: 80px;
        position: relative;
        &:before {
            display: block;
            content: "";
            position: absolute;
            top: 12px;
            left: 0;
            width: 50px;
            background: #cccccc;
            height: 2px;
        }
        p {
            font-family: Lato, sans-serif;
            color: #666666;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            margin-bottom: 30px;
        }
        blockquote {
            font-family: Merriweather, sans-serif;
            color: rgba(0, 0, 0, 0.8);
            font-size: 20px;
            font-style: italic;
            line-height: 32px;
        }
    }
    .controls {
        text-align: center;
        .control-item {
            height: 5px;
            width: 20px;
            display: inline-block;
            vertical-align: middle;
            margin: 0 3px;
            background: $blue;
            border: 1px solid #657da7;
            cursor: pointer;
            &:hover, &.active {
                background: #657da7;
            }
        }
    }
}

.section-special-info {
    padding: 130px 0 110px 0;
    .container {
        padding: 0 40px;
    }
}

.section-whaat-we-do {
    padding: 50px 0 70px;
    text-align: center;
    overflow: hidden;
    .section-title {
        font-family: Raleway, sans-serif;
        color: #ffffff;
        font-size: 38px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    .arrow-bottom {
        display: block;
        margin: 0 auto;
        width: 40px;
        height: 20px;
        background: url(../img/icons/scroll-icon-white.png) top center no-repeat;
        margin-bottom: 70px;
    }
    .what-we-do-content {
        padding: 50px 0 50px;
    }
    .what-we-do-image {
        text-align: center;
        img {
            max-width: 90%;
            vertical-align: middle;
        }
    }
}

.section-what-we-do-2 {
    overflow: hidden;
    .green-col {
        background: $green;
        padding: 70px 15px 50px;
    }
    .welcome-heading {
        font-family: Raleway, sans-serif;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    .welcome-text {
        padding-left: 80px;
        position: relative;
        &:before {
            display: block;
            content: "";
            position: absolute;
            top: 12px;
            left: 0;
            width: 50px;
            background: #fff;
            height: 2px;
        }
        p {
            font-family: Lato, sans-serif;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            margin-bottom: 30px;
        }
        blockquote {
            font-family: Merriweather, sans-serif;
            color: #fff;
            font-size: 20px;
            font-style: italic;
            line-height: 32px;
        }
    }
    .what-we-do-image {
        text-align: center;
        img {
            max-width: 90%;
            vertical-align: middle;
        }
    }
}

.section-working-line {
    padding: 120px 0 150px;
    background: url(../img/sections/section-2.jpg) bottom center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
    &:before {
        content: '';
        background: #f5f5f5;
        opacity: 0.75;
        
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.section-recent-project {
    &.single-work {
        .recent-projects-title {
            font-size: 24px;
            font-family: "Raleway", sans-serif;
            color: #ffffff;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.2;
            margin-bottom: 40px;
            text-align: center;
        }
    }
    .white-section {
        padding: 120px 0 60px;
        h3 {
            line-height: 1;
            margin: 0;
            &:after {
                content: "";
                width: 39px;
                height: 19px;
                background: url(../img/icons/scroll-icon-blue.png) center no-repeat;
                display: block;
                margin: 25px auto 0;
            }
        }
    }
    .blue-section {
        background: $blue;
        padding: 70px 0;
    }
    .gallery-grid {
        padding-bottom: 0;
    }
}

.green-section {
    background: $green;
    &.dotted-pattern {
        background: url(../img/icons/dotted-pattern.png) center repeat $green;
    }
}

.section-hire-us {
    padding: 80px 0;
    h4 {
        margin: 5px 0 0 0;
        color: #fff;
    }
    p {
        font-family: Lato, sans-serif;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
    }
    .logo {
        margin-bottom: 10px;
        display: inline-block;
    }
    .under-logo {
        font-family: Montserrat, sans-serif;
        color: #ffffff;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    .btn {
        max-width: 100%;
    }
}

.section-why-choose-us {
    padding-bottom: 40px;
    .why-choose-us-image {
        margin-left: -125px;
        margin-top: -35px;
    }
    .section-heading {
        padding-top: 110px;
        margin-bottom: 50px;
    }
    .choose-list {
        padding-left: 80px;
    }
}

.section-skills {
    padding: 40px 0 115px;
    overflow: hidden;
    &.skills-2 {
        padding-top: 110px;
        .info-block {
            background: url(../img/skills-img.png) left 105% no-repeat #f5f5f5;
            .info-image {
                left: auto;
                right: -123px;
            }
        }
    }
    .info-block {
        padding: 45px 40px 140px;
        background: url(../img/skills-img.png) right 105% no-repeat #f5f5f5;
        position: relative;
        .info-heading {
            font-family: Raleway, sans-serif;
            color: #333333;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            position: relative;
            padding-left: 40px;
            line-height: 1;
            margin-bottom: 15px;
            &:before {
                display: block;
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
                width: 20px;
                background: #cccccc;
                height: 2px;
            }
        }
        p {
            color: #666666;
            font-size: 14px;
            line-height: 28px;
            padding-left: 40px;
        }
        .info-image {
            position: absolute;
            bottom: -111px;
            left: -123px;
        }
    }
}

.section-our-team {
    &.section-our-team-2 {
        background: url(../img/sections/section-2.jpg) bottom center no-repeat;
        background-size: cover;
        background-attachment: fixed;
        overflow: hidden;
        position: relative;
        &:before {
            content: '';
            background: #f5f5f5;
            opacity: 0.75;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        .white-section {
            background: none;
            position: relative;
        }
        .blue-section {
            background: none;
            position: relative;
            padding-bottom: 120px;
            padding-top: 0;
        }
        .team-controls {
            margin-bottom: 90px;
            .nav {
                &.nav-tabs {
                    li {
                        &:hover, &.active {
                            a {
                                img {
                                    opacity: 0.5;
                                }
                            }
                        }
                        a {
                            padding: 0;
                            position: relative;
                            display: block;
                            background: #000;
                            height: 60px;
                            &:after {
                                content: '';
                                height: 1px;
                                width: 100%;
                                position: absolute;
                                bottom: -20px;
                                background: $green;
                                left: 0;
                            }
                            img {
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }
        .our-team-tabs {
            .tab-content {
                .team-image {
                    margin-bottom: 0;
                }
                .name {
                    color: #333;
                }
                .position {
                    color: #666666;
                }
                .description {
                    p {
                        color: #666666;
                    }
                }
                .socials {
                    ul {
                        li {
                            a {
                                color: #666666;
                                &:hover {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .white-section {
        text-align: center;
        padding: 50px 0;
        h3 {
            line-height: 1;
            margin: 0;
            &:after {
                content: "";
                width: 39px;
                height: 19px;
                background: url(../img/icons/scroll-icon-blue.png) center no-repeat;
                display: block;
                margin: 25px auto 0;
            }
        }
    }
    
    .blue-section {
        background: $blue;
        padding-top: 90px;
        border-bottom: 10px solid $green;
    }
    
    .team-controls {
        margin-bottom: 60px;
        .nav {
            &.nav-tabs {
                text-align: center;
                border: none;
                li {
                    margin: 0 10px;
                    display: inline-block;
                    float: none;
                    border: none;
                    background: none;
                    &:hover, &.active {
                        a {
                            border-bottom: 1px solid $green;
                            img {
                                opacity: 0.2;
                            }
                        }
                    }
                    a {
                        border: none;
                        background: none;
                        padding: 0 0 20px 0;
                        border-bottom: 1px solid transparent;
                        img {
                            width: 60px;
                            height: 60px;
                            vertical-align: middle;
                            transition: all 0.3s ease-out;
                        }
                    }
                }
            }
        }
    }
    
    .our-team-tabs {
        .tab-content {
            .team-image {
                margin-bottom: -30px;
                img {
                    max-width: 100%;
                    vertical-align: middle;
                }
            }
            .name {
                font-family: Raleway, sans-serif;
                color: #ffffff;
                font-size: 20px;
                font-weight: 700;
                line-height: 1;
                margin-bottom: 10px;
                text-transform: uppercase;
            }
            .position {
                color: #c4cfe4;
                font-size: 14px;
                font-weight: 300;
                font-style: italic;
                &:after {
                    content: "";
                    width: 40px;
                    height: 2px;
                    display: block;
                    background: $green;
                    margin: 15px 0 40px;
                }
            }
            .description {
                margin-bottom: 40px;
                p {
                    color: #c4cee4;
                    line-height: 28px;
                }
            }
            .socials {
                margin-bottom: 35px;
                ul {
                    padding: 0;
                    margin: 0;
                    li {
                        display: inline-block;
                        margin: 0 4px;
                        a {
                            display: block;
                            width: 37px;
                            height: 37px;
                            line-height: 37px;
                            text-align: center;
                            border: 1px solid #657da7;
                            border-radius: 50%;
                            color: #7797d0;
                            &:hover {
                                background: $green;
                                border-color: $green;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    
}

.section-comments {
    padding: 140px 0 60px;
    &.services-comments {
        padding-bottom: 110px;
        border-bottom: 1px solid #eee;
    }
    &.home-2-comments {
        padding-bottom: 110px;
        border-bottom: 10px solid $blue;
        color: #ffffff;
        .heading-title {
            color: #ffffff;
        }
        p {
            color: #ffffff;
        }
        .comments-carousel {
            blockquote {
                color: #ffffff;
            }
            .author {
                color: #ffffff;
                .name {
                    color: #ffffff;
                }
            }
        }
    }
}

.section-watch-video {
    padding: 60px 0 0 0;
    .control-button {
        position: absolute;
        top: 90px;
        left: 0;
        right: 0;
        text-align: center;
        > a {
            margin-bottom: 15px;
            display: inline-block;
            span {
                color: #ffffff;
                font-size: 62px;
            }
        }
        > span {
            font-family: Raleway, sans-serif;
            color: #ffffff;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
    .computer {
        text-align: center;
        img {
            max-width: 100%;
            vertical-align: middle;
        }
    }
}

.section-video-action {
    padding: 90px 0;
    h3 {
        color: #ffffff;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 40px;
        line-height: 1.2;
    }
    .section-heading {
        .heading-text {
            &:before {
                background: #fff;
            }
        }
        p {
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
        }
    }
}

.section-latest-news {
    padding: 120px 0 65px;
    h3 {
        line-height: 1;
        margin: 0;
        &:after {
            content: "";
            width: 39px;
            height: 19px;
            background: url(../img/icons/scroll-icon-blue.png) center no-repeat;
            display: block;
            margin: 25px auto 55px;
        }
    }
}

.section-compannies {
    padding: 65px 0 100px;
    &.companies-2 {
        padding-bottom: 0;
    }
    .company-item {
        margin-bottom: 30px;
        img {
            vertical-align: middle;
        }
    }
}

.section-map {
    background: $blue;
    &.contact-section-map {
        padding: 100px 0;
        background: #ffffff;
        .contact-info {
            background: #ffffff;
            color: #333;
            p {
                color: #666666;
            }
        }
        .welcome-feature {
            .feature-name {
                color: #333;
            }
            .feature-text {
                color: #666;
            }
        }
    }
    &.home-2-map {
        padding: 0;
    }
    > .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
    .map-row {
        display: table;
        width: 100%;
        margin: 0;
        @media (max-width: 991px) {
            display: block;
        }
    }
    .custom-map {
        display: table-cell;
        height: 100%;
        vertical-align: middle;
        float: none;
        padding: 0;
        @media (max-width: 991px) {
            height: 300px;
            display: block;
            > div {
                height: 300px !important;
            }
        }
    }
    .contact-info {
        display: table-cell;
        height: 100%;
        vertical-align: middle;
        float: none;
        padding: 110px 0 70px 0;
        background: $blue;
        color: #fff;
        @media (max-width: 991px) {
            display: block;
            padding-bottom: 0;
        }
        p {
            max-width: 450px;
            display: inline-block;
            margin-bottom: 60px;
        }
    }
}

.mini-section-heading {
    h4 {
        margin-bottom: 25px;
        line-height: 1;
    }
    p {
        color: #c4cee4;
        font-size: 14px;
        line-height: 28px;
    }
}

.section-contact {
    padding: 100px 0 230px;
    background: #506fa8;
    position: relative;
    overflow: hidden;
    .section-image {
        position: absolute;
        bottom: 36px;
        left: 20px;
        width: 336px;
        height: 335px;
        background: url(../img/contact-form-image.png) center no-repeat;
    }
    .section-heading {
        .heading-title {
            color: #fff;
        }
        .heading-text {
            p {
                color: #c4cee4;
            }
        }
    }
}

.section-blog-content {
    padding-top: 75px;
    padding-bottom: 120px;
    .left-col {
        padding-right: 45px;
    }
}

.error-section {
    padding: 150px 0;
    .error-container {
        max-width: 960px;
        width: 100%;
        margin: 0 auto 120px;
        padding: 100px 0;
        text-align: center;
        background: $blue;
        position: relative;
        .title {
            display: inline-block;
            padding: 35px 45px;
            border: 1px solid #798db2;
            margin-bottom: 35px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                height: 1px;
                width: 80%;
                left: 10%;
                top: -1px;
                background: $blue;
            }
            &:after {
                content: '';
                position: absolute;
                height: 1px;
                width: 30%;
                left: 35%;
                bottom: -1px;
                background: $blue;
            }
            svg {
                width: 250px;
                vertical-align: middle;
            }
        }
        .subtitle {
            font-size: 30px;
            font-family: "Raleway", sans-serif;
            color: rgb(255, 255, 255);
            text-transform: uppercase;
            line-height: 1;
            text-align: center;
            margin-bottom: 35px;
        }
        .arrow-bottom {
            display: block;
            margin: 0 auto;
            width: 40px;
            height: 20px;
            background: url(../img/icons/scroll-icon-white.png) top center no-repeat;
        }
        .img1 {
            position: absolute;
            top: 58px;
            right: 16px;
            width: 148px;
            height: 142px;
            background: url(../img/tag-img.png) center no-repeat;
        }
        .img2 {
            position: absolute;
            top: 135px;
            left: -60px;
            width: 258px;
            height: 256px;
            background: url(../img/headphones-img.png) center no-repeat;
        }
        .img3 {
            position: absolute;
            bottom: -100px;
            right: 55px;
            width: 133px;
            height: 157px;
            background: url(../img/apple-watch-img.png) center no-repeat;
        }
        @media (max-width: 767px) {
            .img1, .img2, .img3 {
                display: none;
            }
        }
    }
    .under-error-block {
        text-align: center;
        p {
            font-size: 18px;
            color: rgb(102, 102, 102);
            line-height: 1.6;
            text-align: center;
            margin-bottom: 35px;
        }
        a {
            color: $green;
        }
        .btn {
            border: 2px solid #666666;
            color: #333333;
            &:after {
                border-color: transparent transparent $green $green;
            }
        }
    }
}

// Single work section
.single-work-section {
    padding: 120px 0 70px;
    .work-image {
        margin-bottom: 60px;
        .image {
            text-align: center;
            position: relative;
            img {
                max-width: 100%;
            }
            .controls {
                position: absolute;
                bottom: 30px;
                right: 30px;
                .big-view {
                    a {
                        display: block;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background: $green;
                        color: #fff;
                        text-align: center;
                        line-height: 44px;
                        text-decoration: none;
                        transition: all 0.3s ease-out;
                        &:hover {
                            background: darken($green, 10%);
                        }
                        span {
                            color: #fff;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .work-body {
        margin-bottom: 15px;
        .work-body-left, .work-body-right {
            margin-bottom: 30px;
        }
        .work-body-left {
            p {
                padding-left: 30px;
                margin-bottom: 30px;
            }
            a {
                margin-left: 30px;
            }
        }
        h5 {
            font-size: 16px;
            font-weight: 900;
            text-transform: uppercase;
            margin-bottom: 30px;
        }
        .summary-list {
            padding-left: 45px;
            .col-md-12 {
                padding: 15px 15px 15px 0;
                border-bottom: 1px solid #eeeeee;
                &:first-child {
                    padding-top: 0;
                }
            }
            .type-info {
                color: #2a2d32;
                font-size: 12px;
                line-height: 18px;
                font-weight: bold;
                text-transform: uppercase;
                font-family: Raleway, sans-serif;
                i {
                    min-width: 20px;
                    font-size: 14px;
                    color: #666666;
                    display: inline-block;
                    vertical-align: baseline;
                }
            }
            .info {
                color: #666666;
                font-family: Raleway, serif;
                p {
                    line-height: 18px;
                }
                .list-socials {
                    li {
                        display: inline-block;
                        vertical-align: middle;
                        padding: 0 3px;
                        a {
                            color: #7e848e;
                        }
                    }
                }
            }
        }
    }
}

.service-features-section {
    padding: 115px 0 85px;
}

.section-portfolio {
    padding: 110px 0 80px;
    background: url(../img/sections/section-2.jpg) center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    &.without-bg {
        background: #ffffff;
    }
    &:before {
        content: '';
        background: #f5f5f5;
        opacity: 0.75;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.section-pricing {
    padding: 110px 0;
}

.section-join-us {
    padding: 90px 0;
    color: #ffffff;
    background: $blue;
    h4 {
        text-align: center;
        font-size: 30px;
        text-transform: uppercase;
        margin-bottom: 45px;
        font-weight: bold;
    }
    p {
        color: #ffffff;
        position: relative;
        padding-left: 70px;
        &:before {
            content: '';
            height: 2px;
            background: #fff;
            width: 50px;
            margin-right: 20px;
            position: absolute;
            left: 0;
            top: 15px;
        }
    }
}

.section-newsletter {
    .image-col {
        background: url(../img/projects/project-img5.jpg) center no-repeat;
        background-size: cover;
        @media (max-width: 991px) {
            height: 300px;
        }
    }
    .text-col {
        background: $blue;
        color: #ffffff;
        text-align: center;
        padding: 110px 15px;
        p {
            color: #c4cee4;
            margin-bottom: 50px;
        }
        .col-content {
            margin: 0 auto;
            max-width: 500px;
        }
        .form-group {
            margin-bottom: 30px;
        }
    }
}

.section-send-message {
    text-align: center;
    background: $blue;
    padding: 40px 0;
    span {
        content: '';
        width: 30px;
        height: 2px;
        background: #c4cee4;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
    }
    h5 {
        display: inline-block;
        vertical-align: middle;
        color: #c4cee4;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        position: relative;
        margin: 0;
    }
}

@media (max-width: 1199px) {
    .section-hire-us {
        .btn {
            padding: 0 15px;
        }
    }
    .section-why-choose-us .choose-list {
        padding-left: 0;
    }
    
}

@media (max-width: 1199px) {
    .welcome-section {
        padding-bottom: 0;
        &:before {
            display: none;
        }
        .blue-col {
            padding: 50px 15px;
        }
        .welcome-text {
            margin-bottom: 30px;
        }
    }
    .section-whaat-we-do {
        .what-we-do-image {
            padding-top: 50px;
        }
    }
}

@media (max-width: 991px) {
    .section-our-team {
        .our-team-tabs {
            .tab-content {
                .tab-pane {
                    text-align: center;
                    .position {
                        &:after {
                            content: '';
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }
        }
    }
    .section-hire-us {
        text-align: center;
        .col-md-7 {
            margin: 15px 0;
        }
        .text-right {
            text-align: center;
        }
    }
    .section-video-action {
        .col-md-3 {
            text-align: center;
        }
        .btn {
            margin: 15px auto;
        }
    }
    .section-contact {
        padding-bottom: 300px;
    }
    .section-working-line {
        padding-bottom: 100px;
    }
    .section-whaat-we-do {
        &:before {
            display: none;
        }
        .inline-row {
            display: block;
        }
    }
    .section-why-choose-us {
        .why-choose-us-image {
            margin-left: 0;
            text-align: center;
        }
        .section-heading {
            padding-top: 50px;
        }
    }
}

@media (max-width: 767px) {
    .header-section {
        &.home-header {
            min-height: 500px;
        }
        > .container {
            width: 100%;
        }
    }
    .section-working-line {
        padding: 50px 0 50px;
    }
    .section-watch-video .control-button {
        top: 20%;
        a {
            margin-bottom: 5px;
            span {
                font-size: 25px;
            }
        }
        > span {
            font-size: 12px;
        }
    }
    .section-special-info {
        padding: 50px 0;
    }
    .section-whaat-we-do {
        padding-top: 0;
    }
    .section-blog-content {
        .left-col {
            padding-right: 15px;
        }
    }
}

