.gallery-item {
    width: 25%;
    position: relative;
    &:hover {
        .image {
        }
        .item-content {
            opacity: 1;
            box-shadow: 0 0 0 10px $green inset;
        }
    }
    .image {
        width: 100%;
        transition: all 0.3s ease-out;
    }
    img {
        vertical-align: middle;
        width: 100%;
    }
    .item-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        opacity: 0;
        transition: all 0.15s ease-out;
        .view-icon {
            color: $green;
            font-size: 20px;
            text-align: center;
            margin-bottom: 20px;
        }
        .title {
            font-family: Raleway, sans-serif;
            color: #333333;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
        }
        .category {
            font-family: Lato, sans-serif;
            color: #333333;
            font-size: 14px;
            font-weight: 300;
            font-style: italic;
            line-height: 22px;
        }
    }
}


@media (max-width: 991px) {
    .gallery-item {
        width: 50%;
    }
}

@media (max-width: 500px) {
    .gallery-item {
        width: 100%;
    }
}