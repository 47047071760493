.wrap-coming-soon {
    position: relative;
    height: 989px;
    width: 100%;
    overflow: hidden;
    background: url(../img/sections/section-1.jpg) center no-repeat;
    background-size: cover;
    .left-side {
        position: absolute;
        width: 65%;
        left: -19%;
        bottom: -5%;
        top: -40%;
        overflow: hidden;
        background: #ffffff;
        z-index: 2;
        transform: rotate(22deg);
        padding: 40% 0 5% 19%;
        opacity: 0.92;
    }
    .right-side {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        background: $blue;
        z-index: 1;
        opacity: 0.92;
    }
    .side-content {
        position: relative;
        z-index: 3;
        max-width: 1200px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 60px;
        margin: 0 auto;
        .newsletter-input {
            width: 250px;
            display: inline-block;
            .form-control {
                height: 40px;
                border: none;
                border-bottom: 1px solid #738fc4;
                text-transform: uppercase;
                padding-right: 10px;
                padding-left: 32px;
            }
            .form-control-feedback {
                left: 0;
                right: auto;
                top: -1px;
                color: #ffffff;
            }
        }
        .logo {
            width: 180px;
            height: 70px;
            background: url(../img/logo-big.png) center no-repeat;
            cursor: pointer;
            text-decoration: none;
            display: block;
            margin-bottom: 57px;
            margin-left: 7%;
        }
        .launching-image {
            //position: absolute;
            margin-left: 7%;
            margin-bottom: 200px;
            .content-text {
                display: none;
                text-align: left;
            }
        }
        .content-text {
            .content-title {
                text-align: left;
                &:after {
                    width: 90%;
                }
                span {
                    //font-size: 80px;
                    line-height: 1;
                }
            }
            .under-title {
                span {
                    display: block;
                    font-weight: bold;
                    font-size: 22px;
                    text-transform: uppercase;
                    color: #333333;
                    line-height: 1.5;
                }
            }
        }
        .social-list {
            padding-top: 60px;
        }
    }
}

@media (max-width: 1680px) {
    .wrap-coming-soon .side-content .logo {
        margin-bottom: 68px;
    }
}

@media (max-width: 1380px) {
    .wrap-coming-soon .side-content .logo {
        margin-bottom: 78px;
    }
}

@media (max-width: 1200px) {
    .wrap-coming-soon .side-content .launching-image {
        max-width: 47.2%;
    }
}

@media (max-width: 991px) {
    .wrap-coming-soon .side-content .launching-image {
        max-width: 47.8%;
    }
}

@media (max-width: 860px) {
    .wrap-coming-soon .side-content .launching-image {
        max-width: 48%;
    }
}

@media (max-width: 767px) {
    .wrap-coming-soon {
        min-height: 100vh;
        height: auto;
        padding-bottom: 50px;
        .left-side {
            display: none;
        }
    }
    .wrap-coming-soon .side-content {
        .logo {
            margin: 20px auto 50px;
            text-align: center;
        }
        .launching-image {
            max-width: 100%;
            margin: 0 auto;
            text-align: center;
            .content-text {
                display: block;
                color: #ffffff;
                text-align: center;
                .content-title {
                    text-align: left;
                    span {
                        color: #ffffff;
                    }
                }
                .under-title {
                    span {
                        color: #ffffff;
                    }
                }
            }
            img {
                display: none;
            }
        }
        .social-list {
            padding-top: 60px;
            margin-bottom: 30px;
            text-align: center;
            li {
                a {
                    color: #ffffff;
                }
            }
        }
        .counters {
            text-align: center;
            .counter-item {
                margin-left: 20px;
                padding-right: 20px;
                margin-bottom: 15px;
                &:before {
                    display: none;
                }
            }
        }
    }
}