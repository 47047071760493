.choose-item {
    margin-bottom: 45px;
    &:hover, &.active {
        .item-icon {
            background: $green;
            border-color: $green;
            color: #fff;
        }
    }
    .item-icon {
        width: 80px;
        height: 80px;
        border: 1px solid #dfdfdf;
        border-radius: 50%;
        text-align: center;
        line-height: 80px;
        font-size: 26px;
        color: #333333;
    }
    .item-body {
        padding-left: 40px;
        position: relative;
        &:before {
            display: block;
            content: "";
            position: absolute;
            top: 12px;
            left: 0;
            width: 20px;
            background: #cccccc;
            height: 2px;
        }
        .item-heading {
            font-family: Raleway, sans-serif;
            color: #333333;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            padding-top: 7px;
            margin-bottom: 15px;
            line-height: 1;
        }
        p {
            font-family: Lato, sans-serif;
            color: #666666;
            font-size: 14px;
            line-height: 28px;
        }
    }
}