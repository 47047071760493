.blog-item {
    margin-bottom: 80px;
    &.mini-item {
        width: 350px;
        margin: 0 15px 70px;
        max-width: 100%;
        .description-heading {
            padding: 45px 0 0 0;
            .item-info {
                margin: 0 0 15px;
            }
        }
        .item-description {
            padding-left: 0;
            p {
                margin-bottom: 25px;
            }
            &:before {
                content: none;
            }
        }
        .item-title {
            h3 {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 25px;
            }
        }
    }
    .item-image {
        img {
            width: 100%;
        }
    }
    .description-heading {
        position: relative;
        padding-left: 100px;
        padding-top: 50px;
        .date {
            position: absolute;
            left: 0;
            top: -15px;
            width: 70px;
            height: 85px;
            padding-top: 15px;
            background: $blue;
            &:before {
                content: "";
                height: 4px;
                width: 15px;
                background: $blue;
                position: absolute;
                top: -4px;
                left: 0;
            }
            &:after {
                content: "";
                height: 4px;
                width: 15px;
                background: $blue;
                position: absolute;
                top: -4px;
                right: 0;
            }
            .day {
                color: #ffffff;
                font-size: 30px;
                font-weight: 300;
                display: block;
                text-align: center;
            }
            .month {
                display: block;
                text-align: center;
                font-family: Lato, sans-serif;
                color: #ffffff;
                font-size: 14px;
                font-style: italic;
                line-height: 28px;
            }
        }
    }
    .item-title {
        display: block;
        text-decoration: none;
        h3 {
            color: #333333;
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1;
            margin: 0 0 10px;
        }
    }
    .item-info {
        margin-bottom: 30px;
        &.bottom-info {
            ul {
                text-align: right;
            }
        }
        ul {
            li {
                display: inline-block;
                vertical-align: middle;
                color: #666666;
                font-size: 14px;
                font-weight: 300;
                font-style: italic;
                line-height: 22px;
                text-align: left;
                margin-right: 12px;
                a {
                    color: #5cae4a;
                    font-size: 14px;
                    font-weight: 300;
                    font-style: italic;
                    line-height: 22px;
                    text-align: left;
                }
            }
        }
    }
    .item-description {
        padding-left: 100px;
        position: relative;
        &:before {
            content: '';
            width: 80px;
            height: 2px;
            background: #cccccc;
            position: absolute;
            left: 0;
            top: 13px;
        }
        p {

        }
    }
    h4 {
        padding-left: 100px;
    }
    .item-content {
        .item-description {
            p {
                padding: 0;
            }
        }
        p {
            padding-left: 100px;
            margin: 0 0 30px;
        }
        .article-image {
            margin: 0 0 30px;
        }
        .blockquote-with-icon {
            font-family: Merriweather, sans-serif;
            color: #333333;
            font-size: 20px;
            font-style: italic;
            line-height: 36px;
            text-align: left;
            padding-left: 100px;
            position: relative;
            border-left: 2px solid $green;
            margin: 60px 0 60px;
            &:before {
                content: '';
                width: 40px;
                height: 2px;
                background: $green;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:after {
                content: '';
                width: 20px;
                height: 2px;
                background: $green;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            .blockquote-icon {
                position: absolute;
                text-align: center;
                left: 25px;
                top: 50%;
                height: 22px;
                width: 40px;
                margin-top: -11px;
            }
        }
    }
    .item-tags {
        margin-bottom: 60px;
        label {
            font-family: Raleway, sans-serif;
            color: #3f5c91;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            padding-left: 30px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            margin-right: 15px;
            &:before {
                content: '';
                background-color: #ccc;
                width: 20px;
                height: 2px;
                position: absolute;
                left: 0;
                top: 5px;
            }
        }
        a {
            font-family: Montserrat, sans-serif;
            color: #666666;
            font-size: 12px;
            margin-right: 15px;
            font-weight: 400;
            text-align: left;
            text-transform: uppercase;
        }
    }
}

.blog-list-container {
    margin: 0 auto;
    max-width: 100%;
}

@media (max-width: 767px) {
    .blog-item {
        &.mini-item {
            margin-left: 0;
            margin-right: 0;
        }
        h4 {
            padding-left: 50px;
        }
        .item-description {
            padding-left: 50px;
            &:before {
                width: 30px;
            }
        }
        .item-content {
            .blockquote-with-icon {
                margin: 30px 0;
                font-size: 17px;
                padding-left: 80px;
            }
            p {
                padding-left: 50px;
            }
        }
    }
}