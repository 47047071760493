.sidebar-widget {
    margin-bottom: 60px;
    .widget-title {
        font-family: Raleway, sans-serif;
        color: #3f5c91;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 25px;
        padding-left: 30px;
        position: relative;
        &:before {
            content: '';
            background-color: #cccccc;
            width: 20px;
            height: 2px;
            position: absolute;
            left: 0;
            top: 5px;
        }
    }
}

.search-widget {
    .form-group {
        .form-control {
            border: 1px solid #dddddd;
            color: #333;
            &::-webkit-input-placeholder {
                color: #7a7a7a;
                font-style: italic;
            }
            &:-moz-placeholder {
                color: #7a7a7a;
                opacity: 1;
                font-style: italic;
            }
            &::-moz-placeholder {
                color: #7a7a7a;
                opacity: 1;
                font-style: italic;
            }
            &::-ms-placeholder {
                color: #7a7a7a;
                opacity: 1;
                font-style: italic;
            }
        }
        .form-control-feedback {
            height: 50px;
            line-height: 50px;
            color: $green;
            font-size: 16px;
        }
    }
}

.categories-widget {
    ul {
        li {
            border-bottom: 1px solid #eeeeee;
            &:last-child {
                border: none;
            }
            a {
                display: block;
                padding: 15px 0;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                    color: #333333;
                    font-size: 14px;
                    font-weight: 700;
                    &.count {
                        color: $green;
                        font-weight: normal;
                    }
                }
            }
        }
    }
}

.recent-posts-widget {
    .list-posts {
        .list-item {
            padding-bottom: 20px;
            border-bottom: 1px solid #eeeeee;
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 0;
                border: none;
            }
            .post-image {
                width: 65px;
                height: 65px;
                img {
                    height: 100%;
                    width: 100%;
                    vertical-align: middle;
                }
            }
            .post-name {
                font-family: Raleway, sans-serif;
                color: #333333;
                font-size: 14px;
                font-weight: 700;
                line-height: 18px;
                text-transform: uppercase;
                margin-bottom: 10px;
                display: block;
                text-decoration: none;
            }
            .post-info {
                .date {
                    color: $green;
                    font-size: 12px;
                    font-weight: 300;
                    font-style: italic;
                    margin-right: 10px;
                }
                .comments {
                    color: $green;
                    font-size: 12px;
                    font-weight: 300;
                    font-style: italic;
                    i {
                        font-size: 14px;
                        color: #aaaaaa;
                        vertical-align: middle;
                        margin-right: 5px;
                    }
                }
            }
            .media-left {
                padding-right: 20px;
            }
        }
    }
}

.newsletter-widget {
    background: $green;
    padding: 40px 20px;
    .widget-title {
        color: #fff;
        margin-bottom: 20px;
    }
    p {
        color: #fff;
        margin-bottom: 20px;
    }
    .email {
        padding: 15px 10px;
        line-height: 20px;
        margin-bottom: 10px;
        background: #fff;
        text-align: center;
        span {
            color: #666666;
            font-size: 14px;
            font-weight: 300;
            font-style: italic;
        }
    }
    .btn {
        width: 100%;
    }
}

.archives-widget {
    ul {
        li {
            border-bottom: 1px solid #eeeeee;
            &:last-child {
                border: none;
            }
            a {
                display: block;
                padding: 15px 0;
                line-height: 20px;
                color: #333333;
                font-weight: 700;
                font-size: 14px;
            }
        }
    }
}

.tags-widget {
    ul {
        li {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            margin-bottom: 10px;
            a {
                padding: 0 19px;
                line-height: 40px;
                color: #333;
                background: #fff;
                display: inline-block;
                border: 1px solid #dddddd;
                &:hover, &.active {
                    background: $blue;
                    color: #fff;
                    border-color: $blue;
                }
            }
        }
    }
}