.blockquote-article {
    padding: 55px 40px;
    background: #f5f5f5;
    margin-bottom: 90px;
    .name {
        font-family: Raleway, sans-serif;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        padding-left: 30px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin-right: 15px;
        color: #333333;
        margin-bottom: 30px;
        &:before {
            content: '';
            background-color: #ccc;
            width: 20px;
            height: 2px;
            position: absolute;
            left: 0;
            top: 5px;
        }
    }
    .media-left {
        padding-right: 30px;
    }
    .avatar {
        width: 120px;
        max-width: none;
        height: 120px;
    }
    .text {
        font-family: Lato, sans-serif;
        color: #666666;
        font-size: 14px;
        line-height: 28px;
        margin-top: -5px;
        margin-bottom: 20px;
    }
    .social-list {
        li {
            display: inline-block;
            vertical-align: middle;
            margin: 0 10px;
            a {
                color: #666666;
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 767px) {
    .blockquote-article {
        padding: 35px 20px;
    }
}
@media (max-width: 500px) {
    .blockquote-article {
        text-align: center;
        .name {
            margin-bottom: 10px;
            &:before {
                display: none;
            }
        }
        .media {
            .media-left, .media-body {
                display: block;
                width: 100%;
                overflow: visible;
                padding: 0 0 10px;
                text-align: center;
            }
        }
    }
}

