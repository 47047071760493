.projects-carousel {
    .project-item {
        position: relative;
        &:hover {
            .image {
                transform: scale(0.95);
            }
            .item-content {
                opacity: 1;
                transform: scale(0.95);
            }
        }
        .image {
            width: 100%;
            transition: all 0.3s ease-out;
        }
        .item-content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.9);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            opacity: 0;
            transition: all 0.3s ease-out;
            .view-icon {
                color: $green;
                font-size: 20px;
                text-align: center;
                margin-bottom: 20px;
            }
            .title {
                font-family: Raleway, sans-serif;
                color: #333333;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 700;
            }
            .category {
                font-family: Lato, sans-serif;
                color: #333333;
                font-size: 14px;
                font-weight: 300;
                font-style: italic;
                line-height: 22px;
            }
        }
    }
    .owl-dots {
        padding-top: 60px;
        text-align: center;
        .owl-dot {
            height: 5px;
            width: 20px;
            display: inline-block;
            vertical-align: middle;
            margin: 0 3px;
            background: $blue;
            border: 1px solid #657da7;
            cursor: pointer;
            &.active, &:hover {
                background: #657da7;
            }
        }
    }
}