@import "layout/_variables.scss";
body { 
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    color: #333333;
    border-bottom: 400px;
}
* {
    outline: none !important;
}
.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}
.img-responsive {
    display: inline-block;
    vertical-align: middle;
}
img {
    max-width: 100%;
}
.no-padding {
    padding: 0 !important;
}
.vtop {
    display: inline-block;
    vertical-align: top;
    float: none;
}
.vertical-middle { 
    vertical-align: middle;
}
.vertical-bottom {
    vertical-align: bottom;
}
.no-margin {
    margin: 0 !important;
}
.row.row-margin {
    margin-bottom: 40px;
}
.row.no-gutter {
    margin-left: 0;
    margin-right: 0;
}
.row.no-gutter [class*='col-']:not(:first-child), 
.row.no-gutter [class*='col-']:not(:last-child) {
    padding-left: 0;
    padding-right: 0;
}
.relative {
    position: relative;
}
.text-white {
    color: #fff;
}
.no-border {
    border: none !important; 
}
.font-bold {
    font-weight: bold;
    &.btn {
        font-weight: bold;
    }
}
// Google maps
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
.inline-row {
    display: flex;
    align-items: center;
    > [class^="col-"] {
        float: none;
    }
}
.row-table-cell {
    display: table;
    width: 100%;
    margin: 0;
    > [class^="col-"] {
        float: none;
        display: table-cell;
        height: 100%;
    }
    @media (max-width: 991px) {
        display: block;
        > [class^="col-"] {
            display: block;
            height: auto;
        }
    }
}
// THEME STYLES   

@import "_theme.scss";
