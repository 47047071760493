.counters {
    text-align: right;
    .counter-item {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin-left: 50px;
        margin-right: 20px;
        position: relative;
        &:first-child {
            margin-left: 0;
            &:before {
                display: none;
            }
        }
        &:before {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            background: #eeeeee;
            position: absolute;
            left: -45px;
            top: 20px;
        }
        .top-counter {
            margin-bottom: 15px;
            span {
                color: #eeeeee;
                font-weight: bold;
                font-size: 44px;
            }
        }
        .bottom-counter {
            font-size: 14px;
            font-weight: 300;
            color: #ffffff;
            text-transform: uppercase;
        }
    }
}