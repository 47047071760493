.comment-form {
    margin-bottom: 30px;
    h4 {
        margin-bottom: 40px;
    }
    .form-group {
        .form-control {
            border-color: #dddddd;
            color: #666666;
            font-size: 14px;
            font-weight: 300;
            font-style: italic;
            &::-webkit-input-placeholder {
                color: #666666;
            }
            &:-moz-placeholder {
                color: #666666;
                opacity: 1;
            }
            &::-moz-placeholder {
                color: #666666;
                opacity: 1;
            }
            &:-ms-input-placeholder {
                color: #666666;
                opacity: 1;
            }
        }
    }
    textarea {
        min-height: 200px;
        padding-top: 20px;
    }
}