.fancybox-overlay {
    background: rgba(0, 0, 0, 0.5);
    .fancybox-wrap {
        .fancybox-skin {
            box-shadow: none !important;
            border-radius: 0;
            padding: 0 !important;
            .fancybox-close {
                display: none;
            }
        }
    }
}