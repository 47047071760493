#page-preloader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%; 
    height: 100%;
    background: $blue;
    z-index: 100500;
}
 
#page-preloader .spinner {
    display: block;
    position: absolute; 
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    border: 3px solid transparent;
    border-top-color: #ed5c2a;
    border-radius: 50%;
    z-index: 1001;
    -webkit-animation: spin 2.5s infinite linear;
    animation: spin 2.5s infinite linear;
}

#page-preloader .spinner:before, #page-preloader .spinner:after {
    content: '';
    position: absolute;
    border-radius: 50%;
}

#page-preloader .spinner:before {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border: 3px solid transparent;
    border-top-color: #ccc;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
}

#page-preloader .spinner:after {
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border: 3px solid transparent;
    border-top-color: #fff;
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
