.section-heading {
    margin-bottom: 50px;
    h3 {
        line-height: 1;
        margin: 0;
        &:after {
            content: "";
            width: 39px;
            height: 19px;
            background: url(../img/icons/scroll-icon-blue.png) center no-repeat;
            display: block;
            margin: 25px auto 0;
        }
    }
}