.checkbox {
    margin: 0;
    cursor: pointer;
    &.checkbox-success {
        input[type="checkbox"] {
            display: none;
            &:checked + span {
                &:before {
                    background: $green;
                    border-color: $green;
                }
            }
        }
    }
    input[type="checkbox"] {
        display: none;
        &:checked + span {
            &:before {
                content: "\f00c";
                background: $blue;
                border-color: $blue;
            }
        }
    }
    span {
        line-height: 21px;
        font-size: 14px;
        color: #777777;
        font-weight: 300;
        &:before {
            content: '';
            font-family: FontAwesome;
            text-align: center;
            line-height: 19px;
            color: #ffffff;
            display: inline-block;
            vertical-align: middle;
            width: 21px;
            height: 21px;
            border: 1px solid #cccccc;
            margin-right: 10px;
        }
    }
}